import './MaintenanceMessageField.less';
import { Select, Input, Form } from 'antd';

function MaintenanceMessageField({ name, label }) {
  const { TextArea } = Input;

  return (
    <Form.Item name={name} label={label}>
      <TextArea />
    </Form.Item>
  );
}

export default MaintenanceMessageField;
