import React from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDeleteClusterSize } from 'hooks/capacityManagementQueries';

type DeleteClusterSizeActionProps = {
  clusterSizeID: number;
  clusterSizeDisplayName: string;
  operatorAlias: string;
  close: () => void;
  isLoading: boolean;
  canBeDeleted: boolean;
};
function DeleteClusterSizeAction({
  clusterSizeID,
  clusterSizeDisplayName,
  operatorAlias,
  close,
  isLoading,
  canBeDeleted,
}: DeleteClusterSizeActionProps) {
  const { confirm } = Modal;
  const intl = useIntl();

  const isDisabled = isLoading || !canBeDeleted;

  const deleteTitle = intl.formatMessage({
    id: 'cloudCapacityManagement.deleteConfirmModal.title',
  });
  const deleteContent = intl.formatMessage(
    {
      id: 'cloudCapacityManagement.deleteConfirmModal.content',
    },
    {
      clusterSize: <b>{clusterSizeDisplayName}</b>,
      operator: <b>{operatorAlias}</b>,
    },
  );
  const deleteOKText = intl.formatMessage({
    id: 'cloudCapacityManagement.deleteConfirmModal.okText',
  });
  const deleteCancelText = intl.formatMessage({
    id: 'cloudCapacityManagement.deleteConfirmModal.cancel',
  });
  const {
    mutateAsync: mutateDeleteClusterSize,
    isLoading: isDeleteClusterSizeLoading,
  } = useDeleteClusterSize();

  return (
    <Tooltip title={!canBeDeleted ? 'Main sizes cannot be deleted' : ''}>
      <Button
        // type="primary"
        danger
        icon={<DeleteOutlined />}
        loading={isDeleteClusterSizeLoading}
        disabled={isDisabled}
        onClick={() => {
          confirm({
            title: deleteTitle,
            icon: <ExclamationCircleOutlined />,
            content: deleteContent,
            centered: true,
            okText: deleteOKText,
            async onOk() {
              await mutateDeleteClusterSize({ sizeID: clusterSizeID });
              Modal.destroyAll();
              close();
            },
            cancelText: deleteCancelText,
            cancelButtonProps: {
              disabled: isDeleteClusterSizeLoading,
            },
          });
        }}
      >
        Delete Size
      </Button>
    </Tooltip>
  );
}

export default DeleteClusterSizeAction;
