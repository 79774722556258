import { useQuery } from 'react-query';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { getSparkImages } from 'services/sparkimages';
import { SparkImagesResult } from 'types/spark';

export function useSparkImages({
  page = 1,
  search,
}: {
  page?: number;
  search?: string;
}) {
  return useQuery<SparkImagesResult>(
    [QueryServerKeys.SPARK.IMAGES],
    async () => {
      const result = await getSparkImages({ page, search });
      return result?.data;
    },
  );
}
