import React, { useContext, useMemo, useState } from 'react';
import './CloudCapacityManagement.less';
import { Button, Collapse, Tabs, TabsProps, Tooltip, Typography } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import CloudProviderTab from './CloudProviderTab/CloudProviderTab';
import { useGetCloudProviders } from 'hooks/capacityManagementQueries';
import { InstancePlatform } from 'types/cluster';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';
import useCloudProvider from 'hooks/useCloudProvider';

const { Panel } = Collapse;

function CloudCapacityManagement() {
  const { Title } = Typography;

  const { user } = useContext(SessionContext);
  const userHasEditRolesPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.ROLES] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;

  const { data: cloudProviders, isLoading: isCloudProvidersLoading } =
    useGetCloudProviders();
  const { CloudProviderLogoMap } = useCloudProvider();

  const [showNewSizeModal, setShowNewSizeModal] = useState(false);
  const [setshowDeleteSizeModal, setSetshowDeleteSizeModal] = useState(false);
  const [showCellModal, setShowCellModal] = useState(false);
  // const [clickedCellValue, setClickedCellValue] =
  //   useState<ClusterRegionSizeInfo>();

  const [showModalForNewSize, setShowModalForNewSize] = useState(false);

  const tabsItems: TabsProps['items'] = cloudProviders
    ?.filter(
      cloudProvider =>
        ![InstancePlatform.AWS, InstancePlatform.AZURE].includes(
          cloudProvider.key as InstancePlatform,
        ),
    )
    ?.map(cloudProvider => ({
      key: cloudProvider.key,
      label: (
        <span key={cloudProvider.id}>
          {CloudProviderLogoMap.get(cloudProvider.key as InstancePlatform) || (
            <Icon />
          )}
          {cloudProvider.displayName}
        </span>
      ),

      children: (
        <CloudProviderTab
          cloudProvider={cloudProvider}
          showModalForNewSize={showModalForNewSize}
          setShowModalForNewSize={setShowModalForNewSize}
        />
      ),
    }));

  return (
    <>
      <section className="cloud-capacity-management__wrapper">
        <div className="tableTitle">
          <Title>
            <FormattedMessage id="cloudCapacityManagement.pageTitle" />
          </Title>
          <Tooltip
            title={
              !userHasEditRolesPagePermission
                ? 'You don❜t have sufficient permission'
                : 'Create'
            }
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowModalForNewSize(true);
                // setShowCellModal(true);
                // setShowNewSizeModal(true);
              }}
              disabled={isCloudProvidersLoading}
              // disabled={!userHasEditRolesPagePermission} //FIXME:
            >
              <FormattedMessage id="cloudCapacityManagement.createNewSizeButton" />
            </Button>
          </Tooltip>
        </div>
        <Tabs
          centered
          items={tabsItems}
          className="cloud-capacity-management__tabs"
        />
      </section>
      {/* {showNewSizeModal && (
        <SizeModal close={() => setShowNewSizeModal(false)} />
      )} */}
      {/* {showCellModal && (
        <ClusterSizeCellModal close={() => setShowCellModal(false)} />
      )} */}
    </>
  );
}

export default CloudCapacityManagement;
