import React from 'react';
import './ClusterSizeMainInfoStep.less';
import {
  Form,
  InputNumber,
  Input,
  FormInstance,
  Select,
  Tooltip,
  Tag,
  Spin,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { fieldNames } from 'components/guidance/CloudCapacityManagement/ClusterSizeCellModal/ClusterSizeCellModal';
import { useGetClusterSizeRoles } from 'hooks/capacityManagementQueries';
import { ClusterSizesPerClusterCodeItem } from 'types/capacityManagement.types';

function ClusterSizeMainInfoStep({
  isCreateNewSize,
  form,
  initialValues,
}: {
  isCreateNewSize: boolean;
  form?: FormInstance<any>; // FIXME:
  initialValues?: ClusterSizesPerClusterCodeItem & { rolesIDs: number[] };
}) {
  const { Option } = Select;

  if (!!Object.keys(initialValues).length && 'roles' in initialValues) {
    initialValues = {
      ...initialValues,
      rolesIDs: initialValues.roles.map(role => role.roleID),
    };
  }

  const isForCustomSize =
    isCreateNewSize || (!isCreateNewSize && initialValues.isCustomSize);

  const { data: clusterSizeRoles, isLoading: isClusterSizeRolesLoading } =
    useGetClusterSizeRoles({ isForCustomSize });

  return (
    <Form
      name="ClusterSizeMainInfoStep"
      initialValues={initialValues}
      form={form}
    >
      <Form.Item
        name={fieldNames.name}
        label="Name"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        required
        rules={[{ required: true, message: 'Please input Name!' }]}
      >
        <Input disabled={!isCreateNewSize} />
      </Form.Item>
      <Form.Item
        name={fieldNames.displayName}
        label="Display Name"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        required
        rules={[{ required: true, message: 'Please input Display Name!' }]}
      >
        <Input disabled={!isCreateNewSize} />
      </Form.Item>
      <Form.Item
        name={fieldNames.roles}
        label={
          <article className="cluster-size-main-info-step__role-label">
            <label>Roles</label>
            <Tooltip title="You can select multiple roles">
              <InfoCircleOutlined />
            </Tooltip>
          </article>
        }
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        required
        rules={[
          { required: true, message: 'Please select at least one role!' },
        ]}
      >
        {isClusterSizeRolesLoading ? (
          <Spin />
        ) : (
          <Select
            popupClassName="cluster-size-main-info-step__role-selection-popup"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select at least one role"
            optionLabelProp="label"
            loading={isClusterSizeRolesLoading}
            showSearch
            filterOption={(input, option) =>
              option?.label
                ?.toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {clusterSizeRoles?.map(clusterSizeRole => (
              <Option
                key={clusterSizeRole.id}
                value={clusterSizeRole.id}
                label={clusterSizeRole.displayName}
              >
                <article className="cluster-size-main-info-step__role-selection-item">
                  <Tag>{clusterSizeRole.displayName}</Tag>
                </article>
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      {!isCreateNewSize && (
        <Form.Item
          name={fieldNames.ipu}
          label="ipu"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 10 }}
        >
          <InputNumber disabled />
        </Form.Item>
      )}
      {!isCreateNewSize && (
        <Form.Item
          name={fieldNames.icc}
          label="icc"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 10 }}
        >
          <InputNumber disabled />
        </Form.Item>
      )}
    </Form>
  );
}

export default ClusterSizeMainInfoStep;
