import { useContext } from 'react';
import { Space, Modal, Dropdown, MenuProps } from 'antd';
import Icon, {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloudOutlined,
  CheckOutlined,
  EyeOutlined,
  StopOutlined,
  DisconnectOutlined,
  DiffOutlined,
  SwapOutlined,
  UserSwitchOutlined,
  MoreOutlined,
  PartitionOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  AuditOutlined,
  RetweetOutlined,
  LineChartOutlined,
  ProfileOutlined,
  SecurityScanOutlined,
  LaptopOutlined,
  SwapRightOutlined,
  SwapLeftOutlined,
  FormatPainterOutlined,
  AccountBookOutlined,
  UnorderedListOutlined,
  ScheduleOutlined,
  LockOutlined,
  SettingOutlined,
  FileOutlined,
  CrownOutlined,
} from '@ant-design/icons';
import semver from 'semver';
import { ReactComponent as ChidoriBlackIconSVG } from 'images/chidori-black-logo.svg';
import { ReactComponent as NotebookIcon } from 'images/notebook-logo.svg';
import { ReactComponent as ClusterAutoScalingIcon } from 'images/cluster-autoscaling-icon.svg';
import { ReactComponent as ExternalBucketIcon } from 'images/external-bucket.svg';
import { ReactComponent as UpdateIcon } from 'images/update-icon.svg';
import {
  deleteCluster,
  archiveCluster,
  blockClusterFromUrl,
  disconnectCluster,
  connectCluster,
  refreshScripts,
  collectMetrics,
  updateClusterImage,
  updateProbeCheck,
  upgradeToPremiumPackageBulk,
  removePremiumPackageBulk,
} from 'services/cluster';
import { showError, showSucess } from 'utils/errors';
import { SessionContext } from 'auth/SessionProvider';
import { Instance, InstancePlatform } from 'types/cluster';
import { modalKeys } from 'components/Clusters/helper';
import {
  SetActiveModalContext,
  SetModalCountContext,
} from 'components/Clusters/contexts/ClusterContext';
import MenuItemBody from 'components/Clusters/MenuItemBody/MenuItemBody';
import {
  userRolesActions,
  userRolesPages,
  userRolesPagesPermissionsValues,
} from 'types/user';
import { getRelVersion, multiclusterAction } from 'utils/helpers';
import {
  actionDoesNotSupportAzureTooltip,
  checkIsClusterActionEnabledAndGetTooltip,
  notebookTooltipSpecificCondition,
  platformActionsSpecificCondition,
  clusterActionSVGIconStyles,
} from 'utils/clusters';
import { ReactComponent as BoxIcon } from 'images/box.svg';
import usePlatformActions from 'hooks/usePlatformActions';

function ClusterTableActions({
  instance,
  setCurrentInstance,
  refetchClusters,
  currentSelectedRows,
}: {
  instance: Instance;
  setCurrentInstance: React.Dispatch<React.SetStateAction<Instance>>;
  currentSelectedRows: (id: string) => void;
  refetchClusters: Function;
}) {
  const { user } = useContext(SessionContext);

  const isClusterPremium = instance.isPremium;
  const { getEnabledPlatformsForAction } = usePlatformActions({
    platformActions: user.platformActions,
  });
  const clusterUpgradeEnabledPlatform =
    getEnabledPlatformsForAction('cluster_upgrade');
  const clusterCloneEnabledPlatforms = getEnabledPlatformsForAction('clone');
  const clusterArchiveEnabledPlatforms =
    getEnabledPlatformsForAction('cluster_archive');

  const setActiveModal = useContext(SetActiveModalContext);
  const setModalCount = useContext(SetModalCountContext);

  const userHasAnyClusterPagePermission =
    userRolesPages.CLUSTERS in user.userRolesPagesPermissions;
  const userHasEditClusterPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.CLUSTERS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;
  const userHasDeleteClusterPagePermission =
    user.userRolesPagesPermissions?.[userRolesPages.CLUSTERS] ===
    userRolesPagesPermissionsValues.CAN_DELETE;

  // TODO: Get this from the backend.
  const fullVersionTruncated = getRelVersion(
    instance?.image,
  )?.fullVersionTruncated;
  const minimumVersion = '2023.10.0';
  const isBusinessNotebookVersionSupported = !!(
    semver.valid(fullVersionTruncated) &&
    semver.valid(minimumVersion) &&
    semver.gte(fullVersionTruncated, minimumVersion)
  );

  const items: MenuProps['items'] = [
    ...(!instance.deletedAt
      ? [
          ...(userRolesActions.CLUSTER_MANAGEMENT_ACTION in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.EDIT_CLUSTER,
                  label: (
                    <MenuItemBody
                      tooltipTitle="Edit"
                      btnType="text"
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.EDIT_CLUSTER);
                      }}
                    >
                      <EditOutlined />
                      Edit Cluster
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.CLUSTER_CLONE_ACTION in user.userRolesActions &&
          userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.CLONE,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Clone',
                        status: instance.status,
                        checkRunning: true,
                        evaluatePrioritzedCondtion: () =>
                          platformActionsSpecificCondition({
                            action: 'Clone',
                            enabledPlatforms:
                              clusterCloneEnabledPlatforms as InstancePlatform[],
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.CLONE);
                      }}
                    >
                      <DiffOutlined />
                      Clone Cluster
                    </MenuItemBody>
                  ),
                },
                {
                  key: modalKeys.MIGRATION,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Migrate',
                        status: instance.status,
                        checkRunning: true,
                        evaluatePrioritzedCondtion: () => {
                          const isCustomSize =
                            instance.analyticsSize?.isCustomSize ||
                            instance.loaderSize?.isCustomSize;
                          if (isCustomSize) {
                            return {
                              isActionDisabled: true,
                              tooltip:
                                'Clusters with custom size cannot be migrated',
                            };
                          }
                          return actionDoesNotSupportAzureTooltip({
                            action: 'Migrate',
                            platform: instance.platform as InstancePlatform,
                          });
                        },
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.MIGRATION);
                      }}
                    >
                      <SwapOutlined />
                      Migrate Cluster
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.CLUSTER_UPGRADE_ACTION in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.UPGRADE,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Upgrade',
                        status: instance.status,
                        evaluatePrioritzedCondtion: () =>
                          platformActionsSpecificCondition({
                            action: 'Upgrade',
                            enabledPlatforms:
                              clusterUpgradeEnabledPlatform as InstancePlatform[],
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.UPGRADE);
                      }}
                    >
                      <UpdateIcon style={clusterActionSVGIconStyles} />
                      Upgrade Cluster
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userHasAnyClusterPagePermission
            ? [
                {
                  key: modalKeys.CLUSTER_INFO,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle="View Service Status"
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.CLUSTER_INFO);
                      }}
                    >
                      <EyeOutlined />
                      View Service Status
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesPages.AUDIT_HISTORY in user.userRolesPagesPermissions &&
          userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.LOGS,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Logs',
                        status: instance.status,
                        evaluatePrioritzedCondtion: () =>
                          actionDoesNotSupportAzureTooltip({
                            action: 'Logs',
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.LOGS);
                      }}
                    >
                      <FileTextOutlined />
                      Logs
                    </MenuItemBody>
                  ),
                },
                {
                  key: modalKeys.AUDIT_HISTORY,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle="Audit"
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.AUDIT_HISTORY);
                      }}
                    >
                      <AuditOutlined />
                      Audit History
                    </MenuItemBody>
                  ),
                },
                {
                  key: modalKeys.LDAP,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Ldap/Self Sync',
                        status: instance.status,
                        evaluatePrioritzedCondtion: () =>
                          actionDoesNotSupportAzureTooltip({
                            action: 'Ldap/Self Sync',
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.LDAP);
                      }}
                    >
                      <FileSyncOutlined />
                      Ldap/Self Sync
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.TRANSFER_CLUSTER in user.userRolesActions &&
          userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.TRANSFER_OWNERSHIP,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle="Transfer"
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.TRANSFER_OWNERSHIP);
                      }}
                    >
                      <UserSwitchOutlined />
                      Transfer Cluster
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.BLOCK_CLUSTER_FROM_URL in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.BLOCK_URL,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle="URL Block"
                      btnOnClick={() => {
                        setModalCount('single');
                        Modal.confirm({
                          title: (
                            <>
                              Are you sure you want to{' '}
                              {instance.blockFromUrl ? 'unblock' : 'block'}
                              {' starting this cluster '}
                              <strong>{instance.name}</strong> through accessing
                              the URL?
                            </>
                          ),
                          async onOk() {
                            await blockClusterFromUrl(
                              instance.name,
                              instance.blockFromUrl,
                            ).catch(error => {
                              showError(error);
                            });
                            await refetchClusters();
                          },
                        });
                      }}
                    >
                      {instance.blockFromUrl ? (
                        <CheckOutlined />
                      ) : (
                        <StopOutlined />
                      )}
                      {instance.blockFromUrl
                        ? 'Unblock Cluster'
                        : 'Block Cluster'}
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userHasDeleteClusterPagePermission
            ? [
                {
                  key: modalKeys.DELETE,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle="Delete"
                      btnOnClick={() => {
                        setModalCount('single');
                        Modal.confirm({
                          title: (
                            <>
                              Are you sure you want to delete this cluster{' '}
                              <strong>{instance.name}</strong>?
                            </>
                          ),
                          async onOk() {
                            await deleteCluster(instance.name).catch(error => {
                              showError(error);
                            });
                            await refetchClusters();
                            currentSelectedRows(instance.id);
                          },
                        });
                      }}
                    >
                      <DeleteOutlined />
                      Delete Cluster
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.ARCHIVE_CLUSTER in user.userRolesActions &&
          userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.ARCHIVE,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Archive',
                        status: instance.status,
                        evaluatePrioritzedCondtion: () =>
                          platformActionsSpecificCondition({
                            action: 'Archive',
                            enabledPlatforms:
                              clusterArchiveEnabledPlatforms as InstancePlatform[],
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        Modal.confirm({
                          title: (
                            <>
                              Are you sure you want to archive this cluster{' '}
                              <strong>{instance.name}</strong>?
                            </>
                          ),
                          async onOk() {
                            await archiveCluster(instance.name)
                              .then(success => {
                                showSucess(success);
                              })
                              .catch(error => {
                                showError(error);
                              });
                            await refetchClusters();
                            currentSelectedRows(instance.id);
                          },
                        });
                      }}
                    >
                      <SaveOutlined />
                      Archive Cluster
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.CONNECTION_MNGMENT_CLUSTER in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.CONNECTION,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle={
                        instance.status === 'running' ||
                        instance.status === 'starting'
                          ? 'Disconnect'
                          : 'Connect'
                      }
                      isBtnDisabled={instance.status === 'stopping'}
                      btnOnClick={() => {
                        setModalCount('single');
                        Modal.confirm({
                          title: (
                            <>
                              Are you sure you want to{' '}
                              {instance.status === 'running' ||
                              instance.status === 'starting'
                                ? 'Disconnect'
                                : 'Connect'}
                              {' This Cluster '}{' '}
                              <strong>{instance.name}</strong>?
                            </>
                          ),
                          async onOk() {
                            instance.status === 'running' ||
                            instance.status === 'starting'
                              ? await disconnectCluster(instance.name).catch(
                                  error => {
                                    showError(error);
                                  },
                                )
                              : await connectCluster(instance.name).catch(
                                  error => {
                                    showError(error);
                                  },
                                );
                            await refetchClusters();
                          },
                        });
                      }}
                    >
                      {instance.status === 'running' ||
                      instance.status === 'starting' ? (
                        <DisconnectOutlined />
                      ) : (
                        <CloudOutlined />
                      )}
                      {instance.status === 'running' ||
                      instance.status === 'starting'
                        ? 'Disconnect Cluster'
                        : 'Connect Cluster'}
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.ADD_PREMIUM_PACKAGE_ACTION in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: isClusterPremium
                    ? modalKeys.REMOVE_PREMIUM_PACKAGE
                    : modalKeys.PREMIUM_PACKAGE,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle={
                        isClusterPremium
                          ? 'Remove Premium package'
                          : 'Add Premium Package'
                      }
                      btnOnClick={() => {
                        Modal.confirm({
                          icon: (
                            <CrownOutlined
                              {...(isClusterPremium
                                ? { style: { transform: 'rotate(235deg)' } }
                                : {})}
                            />
                          ),
                          title: isClusterPremium
                            ? 'Downgrade to Incorta Standard Package'
                            : 'Upgrade to Incorta Premium Package',
                          content: isClusterPremium
                            ? 'By downgrading the cluster to Incorta Standard Package, you will lose access to additional features and capabilities. Do you wish to proceed?'
                            : 'Upgrading the cluster to Incorta Premium will enable additional features and capabilities, which have licensing implications. Do you wish to proceed?',
                          async onOk() {
                            // FIXME: TEMP:
                            await multiclusterAction(
                              isClusterPremium
                                ? removePremiumPackageBulk
                                : upgradeToPremiumPackageBulk,
                              [[instance.id]],
                              refetchClusters,
                              ({ message, responseMessages }) => {
                                message.error(message);
                              },
                            );
                          },
                        });
                      }}
                    >
                      <CrownOutlined
                        {...(isClusterPremium
                          ? { style: { transform: 'rotate(235deg)' } }
                          : {})}
                      />
                      {isClusterPremium
                        ? 'Remove Premium package'
                        : 'Add Premium Package'}
                    </MenuItemBody>
                  ),
                },
              ]
            : []),

          ...(userRolesActions.CLUSTER_MANAGEMENT_ACTION in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.PROBE_CHECK,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle={
                        instance.enableProbeCheck
                          ? 'Disable Readiness Proble Check'
                          : 'Enable Readiness Proble Check'
                      }
                      btnOnClick={() => {
                        setModalCount('single');
                        Modal.confirm({
                          title: (
                            <>
                              This action will lead to restarting the cluster{' '}
                              <strong>{instance.name}</strong>?
                            </>
                          ),
                          async onOk() {
                            await updateProbeCheck(instance.name).catch(
                              error => {
                                showError(error);
                              },
                            );

                            await refetchClusters();
                          },
                        });
                      }}
                    >
                      <PartitionOutlined />

                      {instance.enableProbeCheck
                        ? 'Disable Readiness Proble Check'
                        : 'Enable Readiness Proble Check'}
                    </MenuItemBody>
                  ),
                },
                {
                  key: modalKeys.REFRESH,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Refresh Scripts',
                        status: instance.status,
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        Modal.confirm({
                          title: (
                            <>
                              Are you sure you want to refresh the cloud scripts
                              for this cluster <strong>{instance.name}</strong>?
                            </>
                          ),
                          async onOk() {
                            await refreshScripts(instance.name).catch(error => {
                              showError(error);
                            });
                          },
                        });
                      }}
                    >
                      <RetweetOutlined />
                      Refresh Scripts
                    </MenuItemBody>
                  ),
                },
                {
                  key: modalKeys.LIBRARIES,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Libraries',
                        status: instance.status,
                        checkRunning: true,
                        evaluatePrioritzedCondtion: () =>
                          actionDoesNotSupportAzureTooltip({
                            action: 'Libraries',
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.LIBRARIES);
                      }}
                    >
                      <Icon component={BoxIcon} />
                      Libraries
                    </MenuItemBody>
                  ),
                },
                {
                  key: modalKeys.COLLECT_METRICS,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Collect Metrics',
                        status: instance.status,
                        checkRunning: true,
                        evaluatePrioritzedCondtion: () =>
                          actionDoesNotSupportAzureTooltip({
                            action: 'Collect Metrics',
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        Modal.confirm({
                          title: (
                            <>
                              Are you sure you want to collect the metrics for
                              this cluster <strong>{instance.name}</strong>?
                            </>
                          ),
                          async onOk() {
                            await collectMetrics(instance.name).catch(error => {
                              showError(error);
                            });
                          },
                        });
                      }}
                    >
                      <LineChartOutlined />
                      Collect Metrics
                    </MenuItemBody>
                  ),
                },
                {
                  key: modalKeys.PROGRESS,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Progress Logs',
                        status: instance.status,
                        checkRunning: true,
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.PROGRESS);
                      }}
                    >
                      <ProfileOutlined />
                      Progress Logs
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesPages.FEATURE_BITS in user.userRolesPagesPermissions &&
          userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.FEATURE_BITS,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Feature Bits',
                        status: instance.status,
                        checkRunning: true,
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.FEATURE_BITS);
                      }}
                    >
                      <LaptopOutlined />
                      Feature bits
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.CLUSTER_MANAGEMENT_ACTION in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.RLanguage,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: instance.rImage
                          ? 'Disable R in Image'
                          : 'Enable R in Image',
                        status: instance.status,
                        checkRunning: true,
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        Modal.confirm({
                          title: (
                            <>
                              This action will lead to restarting the cluster{' '}
                              <strong>{instance.name}</strong>?
                            </>
                          ),
                          async onOk() {
                            await updateClusterImage(instance.name).catch(
                              error => {
                                showError(error);
                              },
                            );

                            await refetchClusters();
                          },
                        });
                      }}
                    >
                      {instance.rImage ? (
                        <SwapRightOutlined />
                      ) : (
                        <SwapLeftOutlined />
                      )}
                      {instance.rImage
                        ? 'Disable R in Image'
                        : 'Enable R in Image'}
                    </MenuItemBody>
                  ),
                },
                {
                  key: modalKeys.CUSTOMIZE,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Customize',
                        status: instance.status,
                        checkRunning: true,
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.CUSTOMIZE);
                      }}
                    >
                      <FormatPainterOutlined />
                      Cluster Customization
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.CLUSTER_SECRET_ACTION in user.userRolesActions &&
          userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.SECRETS,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle={'Secret'}
                      btnOnClick={() => {
                        setModalCount('single');
                        Modal.confirm({
                          title: (
                            <>
                              Are you sure you want to retrieve senstive data
                              for this cluster <strong>{instance.name}</strong>?
                            </>
                          ),
                          async onOk() {
                            setActiveModal(modalKeys.SECRETS);
                          },
                        });
                      }}
                    >
                      <SecurityScanOutlined />
                      Cluster Secrets
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
        ]
      : []),
    ...(userRolesActions.CLUSTER_COST_MANAGEMENT_ACTION in
      user.userRolesActions && userHasEditClusterPagePermission
      ? [
          {
            key: modalKeys.SLA_COST,
            label: (
              <MenuItemBody
                btnType="text"
                {...checkIsClusterActionEnabledAndGetTooltip({
                  action: 'SLA and Cost Options',
                  status: instance.status,
                })}
                btnOnClick={() => {
                  setModalCount('single');
                  setActiveModal(modalKeys.SLA_COST);
                }}
              >
                <AccountBookOutlined />
                Cost Configurations
              </MenuItemBody>
            ),
          },
          {
            key: modalKeys.CLUSTER_MAINTENANCE,
            label: (
              <MenuItemBody
                btnType="text"
                {...checkIsClusterActionEnabledAndGetTooltip({
                  action: 'Set cluster maintenance timeout',
                  status: instance.status,
                  evaluatePrioritzedCondtion: () =>
                    actionDoesNotSupportAzureTooltip({
                      action: 'Set cluster maintenance timeout',
                      platform: instance.platform as InstancePlatform,
                    }),
                })}
                btnOnClick={() => {
                  setModalCount('single');
                  setActiveModal(modalKeys.CLUSTER_MAINTENANCE);
                }}
              >
                <SettingOutlined />
                Cluster Maintenance
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(!instance.deletedAt
      ? [
          ...(userHasEditClusterPagePermission
            ? [
                ...(userRolesActions.CLUSTER_ROLES in user.userRolesActions
                  ? [
                      {
                        key: modalKeys.USER_ROLES,
                        label: (
                          <MenuItemBody
                            btnType="text"
                            tooltipTitle={'List instance users'}
                            btnOnClick={() => {
                              setModalCount('single');
                              setActiveModal(modalKeys.USER_ROLES);
                            }}
                          >
                            <UnorderedListOutlined />
                            Instance users
                          </MenuItemBody>
                        ),
                      },
                    ]
                  : []),

                ...(userRolesActions.CLUSTER_MANAGEMENT_ACTION in
                user.userRolesActions
                  ? [
                      {
                        key: modalKeys.SCHEDULE,
                        label: (
                          <MenuItemBody
                            btnType="text"
                            {...checkIsClusterActionEnabledAndGetTooltip({
                              action: 'Scheduler',
                              status: instance.status,
                              evaluatePrioritzedCondtion: () =>
                                actionDoesNotSupportAzureTooltip({
                                  action: 'Scheduler',
                                  platform:
                                    instance.platform as InstancePlatform,
                                }),
                            })}
                            btnOnClick={() => {
                              setModalCount('single');
                              setActiveModal(modalKeys.SCHEDULE);
                            }}
                          >
                            <ScheduleOutlined />
                            Schedule
                          </MenuItemBody>
                        ),
                      },
                    ]
                  : []),
              ]
            : []),
          ...(userRolesActions.LOCK_CLUSTER in user.userRolesActions
            ? [
                {
                  key: modalKeys.LOCK_WINDOW,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      tooltipTitle="Lock Actions"
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.LOCK_WINDOW);
                      }}
                    >
                      <LockOutlined />
                      Locked Actions
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesPages.CHIDORI in user.userRolesPagesPermissions
            ? [
                {
                  key: modalKeys.SPARK_MASTER,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Chidori',
                        status: instance.status,
                        checkRunning: true,
                        loaderStatus:
                          instance.instanceServices[0].loader_status,
                        evaluatePrioritzedCondtion: () =>
                          actionDoesNotSupportAzureTooltip({
                            action: 'Chidori',
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.SPARK_MASTER);
                      }}
                    >
                      <ChidoriBlackIconSVG style={clusterActionSVGIconStyles} />
                      Chidori
                    </MenuItemBody>
                  ),
                },
              ]
            : []),

          ...(userRolesActions.BUSINEE_NOTEBOOK_ACTION in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.NOTEBOOK,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Notebook',
                        status: instance.status,
                        checkRunning: true,
                        loaderStatus:
                          instance.instanceServices[0].loader_status,
                        evaluatePrioritzedCondtion: () =>
                          notebookTooltipSpecificCondition({
                            isBusinessNotebookVersionSupported,
                            minimumVersion,
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.NOTEBOOK);
                      }}
                    >
                      <NotebookIcon style={clusterActionSVGIconStyles} />
                      Business Notebook
                    </MenuItemBody>
                  ),
                },
              ]
            : []),

          ...(userRolesActions.CLUSTER_MANAGEMENT_ACTION in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.AUTO_SCALING,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Auto Scaling',
                        status: instance.status,
                        checkRunning: true,
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.AUTO_SCALING);
                      }}
                    >
                      <ClusterAutoScalingIcon
                        style={clusterActionSVGIconStyles}
                      />
                      Auto Scaling
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.CLUSTER_MANAGEMENT_ACTION in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.DISK_UPSIZE,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'Disk Upsize',
                        status: instance.status,
                        checkRunning: true,
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.DISK_UPSIZE);
                      }}
                    >
                      <FileOutlined />
                      Disk Upsize
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
          ...(userRolesActions.CLUSTER_MANAGEMENT_ACTION in
            user.userRolesActions && userHasEditClusterPagePermission
            ? [
                {
                  key: modalKeys.EXTERNAL_BUCKET,
                  label: (
                    <MenuItemBody
                      btnType="text"
                      {...checkIsClusterActionEnabledAndGetTooltip({
                        action: 'External Bucket',
                        status: instance.status,
                        checkRunning: false,
                        evaluatePrioritzedCondtion: () =>
                          actionDoesNotSupportAzureTooltip({
                            action: 'External Bucket',
                            platform: instance.platform as InstancePlatform,
                          }),
                      })}
                      btnOnClick={() => {
                        setModalCount('single');
                        setActiveModal(modalKeys.EXTERNAL_BUCKET);
                      }}
                    >
                      <ExternalBucketIcon style={clusterActionSVGIconStyles} />
                      External Bucket
                    </MenuItemBody>
                  ),
                },
              ]
            : []),
        ]
      : []),
  ];

  return (
    <Space wrap>
      {
        <Dropdown
          trigger={['click']}
          menu={{ items }}
          onOpenChange={(open: boolean) => {
            if (open) {
              setCurrentInstance(instance);
            }
          }}
        >
          <MoreOutlined />
        </Dropdown>
      }
    </Space>
  );
}

export default ClusterTableActions;
