import React from 'react';
import './SizeField.less';
import { Select, Form } from 'antd';
import { useClusterSizesForUserRoleAndPlatformAndOperator } from 'hooks/capacityManagementQueries';

function SizeField({
  name,
  label,
  instanceID,
  pod,
}: {
  name: string;
  label: string;
  instanceID: string;
  pod: 'analytics' | 'loader';
}) {
  const {
    data: clusterSizesForUserRoleAndPlatformAndOperator,
    isLoading: isClusterSizesForUserRoleAndPlatformAndOperatorLoading,
  } = useClusterSizesForUserRoleAndPlatformAndOperator({
    instanceID,
    pod,
  });
  const sizes = clusterSizesForUserRoleAndPlatformAndOperator?.sizes;

  return (
    <Form.Item
      className="SizeField"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <Select
        loading={
          isClusterSizesForUserRoleAndPlatformAndOperatorLoading && !sizes
        }
        popupClassName="SizeField__select-dropdown"
      >
        {sizes?.map(size => (
          <Select.Option key={size.id} value={size.id}>
            {size.displayName}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SizeField;
