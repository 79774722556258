import React from 'react';
import { useIntl } from 'react-intl';
import ErrorPage from 'components/ErrorPages/ErrorPage';

function MFARequired() {
  const intl = useIntl();
  return (
    <ErrorPage
      headerContent={
        <>
          {intl.formatMessage({
            id: 'auth0.errorPages.mfaRequired.headerLine1',
          })}
        </>
      }
      subheaderContent={<></>}
    ></ErrorPage>
  );
}

export default MFARequired;
