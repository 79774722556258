import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { displaySuccessMessage, displayErrorMessage } from 'utils';
import { getErrorMessage } from 'utils/errors';
import { queryClient } from 'index';
import { CustomizationResult } from 'types/cluster';
import { customizeInstance, getInstanceCustomization } from 'services/cluster';

export function useClusterCustomization({
  clusterName,
}: {
  clusterName: string;
}) {
  return useQuery<CustomizationResult>(
    [QueryServerKeys.CLUSTER_CUSTOMIZATION.CUSTOMIZATION_LIST],
    async () => {
      const result = await getInstanceCustomization(clusterName);
      return result?.data;
    },
  );
}

export function useCustomizeCluster({ clusterName }: { clusterName: string }) {
  const queryOptions = useMutation(
    async ({ payload }: { payload: any }) => {
      return await customizeInstance(clusterName, payload);
    },
    {
      mutationKey: [QueryServerKeys.CLUSTER_CUSTOMIZATION.CUSTOMIZE_CLUSTER],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.CLUSTER_MANAGEMENT.CLUSTERS,
        );
        queryClient.invalidateQueries([
          QueryServerKeys.CAPACITY_MANAGEMENT
            .CLUSTER_SIZES_FOR_USER_ROLE_PLATFORM_OPERATOTR,
        ]);
        displaySuccessMessage(
          result.status.toString().startsWith('2')
            ? `Cluster is successfully customized`
            : result.data?.message,
        );
      },
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}
