import { AxiosError } from 'axios';
import { useQuery, useMutation } from 'react-query';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { displayErrorMessage, displaySuccessMessage } from 'utils';
import { getErrorMessage } from 'utils/errors';
import {
  createNewClusterSize,
  deleteClusterSize,
  getCloudProviders,
  getClusterSizeOperators,
  getClusterSizesPerClusterCode,
  getClusterSizesPerProvider,
  getNodeLabels,
  getAffectedInstancesFromModifiedClusterSize,
  updateClusterSize,
  getClusterSizeRoles,
  getClusterSizesForUserRoleAndPlatformAndOperator,
} from 'services/capacityManagement';
import { InstancePlatform } from 'types/cluster';
import {
  NewClusterSizeBody,
  UpdatedClusterSize,
} from 'types/capacityManagement.types';
import { queryClient } from 'index';

export function useGetCloudProviders() {
  return useQuery(
    [QueryServerKeys.CAPACITY_MANAGEMENT.CLOUD_PROVIDERS],
    async () => {
      try {
        const result = await getCloudProviders();
        return result?.data?.providers;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useClusterSizesPerCloudProviders({
  cloudProviderID,
}: {
  cloudProviderID: string;
}) {
  return useQuery(
    [
      QueryServerKeys.CAPACITY_MANAGEMENT.CLUSTER_SIZES_PER_PROVIDER,
      cloudProviderID,
    ],
    async () => {
      try {
        const result = await getClusterSizesPerProvider({ cloudProviderID });
        return result?.data?.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useClusterSizesPerClusterCode({
  clusterCode,
}: {
  clusterCode: string;
}) {
  return useQuery(
    [
      QueryServerKeys.CAPACITY_MANAGEMENT
        .CLUSTER_SIZE_OPERATORS_PER_CLUSTER_CODE,
      clusterCode,
    ],
    async () => {
      try {
        const result = await getClusterSizesPerClusterCode({
          clusterCode,
        });
        const data = result?.data.sizes?.slice(0, 6);
        return data;
        // return data.map(d => ({
        //   ...d,
        //   overCommitment: JSON.parse(d.overCommitment),
        // }));
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: !!clusterCode,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useGetOperatorsPerPlatform({
  platform,
  operatorNameFilter,
}: {
  platform: InstancePlatform;
  operatorNameFilter?: string;
}) {
  return useQuery(
    [
      QueryServerKeys.CAPACITY_MANAGEMENT.CLUSTER_SIZE_OPERATORS_PER_PROVIDER,
      platform,
      // ...(operatorNameFilter ? [operatorNameFilter] : []),
    ],
    async () => {
      try {
        const result = await getClusterSizeOperators({ platform });
        return result;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      select(res) {
        if (operatorNameFilter) {
          const filterOperator = res.data.platformOperators.filter(
            operator => operator.clusterCode === operatorNameFilter,
          );
          res = {
            ...res,
            data: {
              ...res.data,
              platformOperators: filterOperator,
            },
          };
        }
        return res;
      },
      onSuccess(res) {},
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useGetClusterSizeRoles({
  isForCustomSize,
}: {
  isForCustomSize: boolean;
}) {
  return useQuery(
    [QueryServerKeys.CAPACITY_MANAGEMENT.CLUSTER_SIZE_ROLES, isForCustomSize],
    async () => {
      try {
        const result = await getClusterSizeRoles({ isForCustomSize });
        return result?.data?.roles;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

// TODO: remove
// export function useGetClusterSizesPerProvider() {
//   return useQuery(
//     [QueryServerKeys.CAPACITY_MANAGEMENT.CLUSTER_SIZE_OPERATORS_PER_PROVIDER],
//     async () => {
//       try {
//         const result = await getClusterSizeOperators();
//         return result?.data?.operators?.operators;
//       } catch (error) {
//         throw error;
//       }
//     },
//     {
//       refetchOnWindowFocus: false,
//       refetchOnReconnect: false,
//       refetchOnMount: false,
//       onError(error: AxiosError) {
//         const errorMessage = error && getErrorMessage(error);
//         if (errorMessage) {
//           displayErrorMessage(errorMessage);
//         }
//       },
//     },
//   );
// }

export function useGetNodeLabels({
  region,
  platform,
  k8sClusterCode,
}: {
  region: string;
  platform: InstancePlatform;
  k8sClusterCode: string;
}) {
  return useQuery(
    [QueryServerKeys.CAPACITY_MANAGEMENT.NODE_LABELS],
    async () => {
      try {
        const result = await getNodeLabels({
          region,
          platform,
          k8sClusterCode,
        });
        return result?.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useGetAffectedInstancesFromModifiedClusterSize({
  sizeID,
}: {
  sizeID: number;
}) {
  return useQuery(
    [QueryServerKeys.CAPACITY_MANAGEMENT.AFFECTED_CLUSTERS, sizeID],
    async () => {
      try {
        const result = await getAffectedInstancesFromModifiedClusterSize({
          sizeID,
        });
        return result?.data.instances;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useCreateNewClusterSize() {
  const queryOptions = useMutation({
    mutationKey: QueryServerKeys.CAPACITY_MANAGEMENT.CREATE_MEW_CLUSTER_SIZE,
    mutationFn: async ({
      newClusterSize,
    }: {
      newClusterSize: NewClusterSizeBody;
    }) => {
      const res = await createNewClusterSize({ newClusterSize });
      return res;
    },
    onSuccess(result) {
      queryClient.invalidateQueries(
        QueryServerKeys.CAPACITY_MANAGEMENT.CLUSTER_SIZES_PER_PROVIDER,
      );
      displaySuccessMessage(result.data.message);
    },
    onError(error: any) {
      let errorMessage = error && getErrorMessage(error);
      if (errorMessage) {
        displayErrorMessage(errorMessage);
      }
    },
  });
  return queryOptions;
}

export function useUpdateClusterSize() {
  const queryOptions = useMutation({
    mutationKey: QueryServerKeys.CAPACITY_MANAGEMENT.UPDATE_CLUSTER_SIZE,
    mutationFn: async ({
      updatedClusterSize,
      sizeID,
    }: {
      updatedClusterSize: UpdatedClusterSize;
      sizeID: number;
    }) => {
      const res = await updateClusterSize({ updatedClusterSize, sizeID });
      return res;
    },
    onSuccess(result) {
      queryClient.invalidateQueries(
        QueryServerKeys.CAPACITY_MANAGEMENT.CLUSTER_SIZES_PER_PROVIDER,
      );
      displaySuccessMessage(result.data.message);
    },
    onError(error: any) {
      let errorMessage = error && getErrorMessage(error);
      if (errorMessage) {
        displayErrorMessage(errorMessage);
      }
    },
  });
  return queryOptions;
}

export function useDeleteClusterSize() {
  const queryOptions = useMutation({
    mutationKey: QueryServerKeys.CAPACITY_MANAGEMENT.DELETE_CLUSTER_SIZE,
    mutationFn: async ({ sizeID }: { sizeID: number }) => {
      const res = await deleteClusterSize({ sizeID });
      return res;
    },
    onSuccess(result) {
      queryClient.invalidateQueries(
        QueryServerKeys.CAPACITY_MANAGEMENT.CLUSTER_SIZES_PER_PROVIDER,
      );
      displaySuccessMessage(result.data.message);
    },
    onError(error: any) {
      let errorMessage = error && getErrorMessage(error);
      if (errorMessage) {
        displayErrorMessage(errorMessage);
      }
    },
  });
  return queryOptions;
}

export function useClusterSizesForUserRoleAndPlatformAndOperator({
  instanceID,
  pod,
}: {
  instanceID: string;
  pod: 'analytics' | 'loader';
}) {
  return useQuery(
    [
      QueryServerKeys.CAPACITY_MANAGEMENT
        .CLUSTER_SIZES_FOR_USER_ROLE_PLATFORM_OPERATOTR,
      instanceID,
    ],
    async () => {
      try {
        const result = await getClusterSizesForUserRoleAndPlatformAndOperator({
          instanceID,
        });
        return result.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      select(data) {
        const shouldAddAnalyticsSize =
          pod === 'analytics' &&
          data.sizes.findIndex(d => d.id === data?.analyticsSize.id) < 0
            ? true
            : false;
        const shouldAddLoaderSize =
          pod === 'loader' &&
          data.sizes.findIndex(d => d.id === data?.loaderSize.id) < 0
            ? true
            : false;
        const newArr = {
          sizes: [
            ...data?.sizes,
            ...(shouldAddAnalyticsSize ? [data?.analyticsSize] : []),
            ...(shouldAddLoaderSize ? [data?.loaderSize] : []),
          ],
        };
        return newArr;
      },
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}
