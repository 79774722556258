import './FullPageLoader.less';

import React from 'react';
import { Spin } from 'antd';

function FullPageLoader() {
  return (
    <div className="FullPageLoader">
      <Spin />
    </div>
  );
}

export default FullPageLoader;
