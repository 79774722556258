import axios from 'axios';
import {
  AffectedInstancesFromModifiedSizeResponse,
  ClusterSizeOperatorsResponse,
  ClusterSizeRolesResponse,
  ClusterSizesCloudProviders,
  ClusterSizesForUserRoleAndPlatformAndOperator,
  ClusterSizesPerClusterCodeResponse,
  ClusterSizesResponse,
  CreateClusterSizeResponse,
  DeleteClusterSizeResponse,
  NewClusterSizeBody,
  NodeLabelsResponse,
  UpdateClusterSizeResponse,
  UpdatedClusterSize,
} from 'types/capacityManagement.types';
import { InstancePlatform } from 'types/cluster';
import { API_URL } from 'utils/http';

export function getCloudProviders() {
  return axios.get<ClusterSizesCloudProviders>(
    `${API_URL}/size/cloudproviders`,
  );
}

export function getClusterSizesPerProvider({
  cloudProviderID,
}: {
  cloudProviderID: string;
}) {
  return axios.get<ClusterSizesResponse>(`${API_URL}/size/${cloudProviderID}`);
}

export function getClusterSizesPerClusterCode({
  clusterCode,
}: {
  clusterCode: string;
}) {
  return axios.get<ClusterSizesPerClusterCodeResponse>(
    `${API_URL}/size?clusterCode=${clusterCode}`,
  );
}

export function getClusterSizeOperators({
  platform,
}: {
  platform: InstancePlatform;
}) {
  return axios.get<ClusterSizeOperatorsResponse>(
    `${API_URL}/size/operators?platform=${platform}`,
  );
}

export function getClusterSizeRoles({ isForCustomSize }) {
  return axios.get<ClusterSizeRolesResponse>(
    `${API_URL}/size/roles/${isForCustomSize}`,
  );
}

export function getNodeLabels({
  region,
  platform,
  k8sClusterCode,
}: {
  region: string; //region of the selected operator
  platform: InstancePlatform; //platform of the selected operator
  k8sClusterCode: string; //k8sClusterCode of the selected operator
}) {
  return axios.post<NodeLabelsResponse>(`${API_URL}/size/labels`, {
    region,
    platform,
    k8sClusterCode,
  });
}

export function createNewClusterSize({
  newClusterSize,
}: {
  newClusterSize: NewClusterSizeBody; //k8sClusterCode of the selected operator
}) {
  return axios.post<CreateClusterSizeResponse>(`${API_URL}/size/create`, {
    ...newClusterSize,
  });
}

export function getAffectedInstancesFromModifiedClusterSize({
  sizeID,
}: {
  sizeID: number;
}) {
  return axios.post<AffectedInstancesFromModifiedSizeResponse>(
    `${API_URL}/size/warn`,
    {
      sizeID,
    },
  );
}

export function updateClusterSize({
  updatedClusterSize,
  sizeID,
}: {
  updatedClusterSize: UpdatedClusterSize;
  sizeID: number;
}) {
  return axios.put<UpdateClusterSizeResponse>(`${API_URL}/size/${sizeID}`, {
    ...updatedClusterSize,
  });
}

export function deleteClusterSize({ sizeID }: { sizeID: number }) {
  return axios.delete<DeleteClusterSizeResponse>(`${API_URL}/size/${sizeID}`);
}

export function getClusterSizesForUserRoleAndPlatformAndOperator({
  instanceID,
}: {
  instanceID: string;
}) {
  return axios.get<ClusterSizesForUserRoleAndPlatformAndOperator>(
    `${API_URL}/instances/customization/sizes?includeAll=true&instanceID=${instanceID}`,
  );
}
