import Icon from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import { InstancePlatform } from 'types/cluster';
import { ReactComponent as AzureLogo } from 'images/azure-a-logo.svg';
import { ReactComponent as AzureSaaSLogo } from 'images/azure-saas-logo.svg';
import { ReactComponent as GoogleCloudLogo } from 'images/google-cloud-logo.svg';
import { ReactComponent as AWSLogo } from 'images/aws-logo.svg';

function useCloudProvider() {
  const CloudProviderLogoMap = new Map([
    [InstancePlatform.GC, <Icon component={GoogleCloudLogo} />],
    [InstancePlatform.AZURE, <Icon component={AzureLogo} />],
    [InstancePlatform.AZURE_SAAS, <Icon component={AzureSaaSLogo} />],
    [InstancePlatform.AWS, <Icon component={AWSLogo} />],
  ]);

  return { CloudProviderLogoMap };
}

export default useCloudProvider;
