import React, { useState, useCallback, useContext } from 'react';
import { Typography, Tag, Button, Tooltip, Space, Modal } from 'antd';
import { format } from 'date-fns';
import {
  EditOutlined,
  DeleteOutlined,
  CloudOutlined,
  CheckOutlined,
  StopOutlined,
  CloudUploadOutlined,
  DisconnectOutlined,
  DiffOutlined,
  UserSwitchOutlined,
  AccountBookOutlined,
} from '@ant-design/icons';
import ClusterModal from '../../Clusters/ClusterModal/ClusterModal';
import UpgradeModal from '../../Clusters/UpgradeModal/UpgradeModal';
import CloneModal from '../../Clusters/CloneModal/CloneModal';
import TransferOwnershipModel from '../../Clusters/TransferOwnershipModel/TransferOwnershipModel';
import useRequest from 'hooks/useRequest';
import {
  getClusters,
  deleteCluster,
  blockClusterFromUrl,
  disconnectCluster,
  connectCluster,
} from 'services/cluster';
import SlaCostModal from 'components/Clusters/SlaCostModal/SlaCostModal';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import {
  userRolesActions,
  userRolesPages,
  userRolesPagesPermissionsValues,
} from 'types/user';
import { SessionContext } from 'auth/SessionProvider';
import { Instance } from 'types/cluster';

const { Title } = Typography;

function InstanceDetails({ userID }) {
  const { user } = useContext(SessionContext);
  const userHasEditClusterPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.CLUSTERS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;
  const userHasDeleteClusterPagePermission =
    user.userRolesPagesPermissions?.[userRolesPages.CLUSTERS] ===
    userRolesPagesPermissionsValues.CAN_DELETE;

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [userid] = useState(userID);
  const [modalProps, setModalProps] = useState<any>(null);
  const [cloneModalProps, setCloneModalProps] = useState<any>(null);
  const [transferOwnershipModelProps, setTransferOwnershipModelProps] =
    useState<any>(null);
  const [upgradeModalProps, setUpgradeModalProps] = useState<any>(null);

  const [slaCostModalProps, setSlaCostModalProps] = useState<any>(null);

  const {
    result,
    isPending,
    makeRequest: refetchClusters,
  } = useRequest(
    useCallback(
      () => getClusters({ page, search, userid }),
      [page, search, userid],
    ),
    { resolve: true },
  );

  const clusters = result?.data?.instances;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const searchColumns = [
    'Name',
    'Owner Email',
    'Status',
    'Cost Center',
    'Organization',
    'Incorta Version',
    'Type',
    'Created At',
    'Deleted',
    'Data Agent',
    'Cloned',
    'SQLX',
    'SQLI',
    'Self Managed',
    'Alive',
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Owner Email',
      render(_, instance) {
        return instance.user.email;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Cost Center',
      dataIndex: 'cost_center',
      width: '10%',
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      width: '13%',
    },
    {
      title: 'Region',
      dataIndex: 'region',
    },
    {
      title: 'Incorta Version',
      render(_, instance) {
        return (
          <span>
            {instance.customBuildName || instance.customBuild || instance.image}
          </span>
        );
      },
    },
    {
      title: 'Type',
      render(_, instance: Instance) {
        const isAnalyticsAndLoaderSameSize =
          instance?.analyticsSizeID === instance?.loaderSizeID;
        if (isAnalyticsAndLoaderSameSize) {
          return (
            <section className="clusters__size-cell center">
              <label>{instance?.analyticsSize?.displayName}</label>
            </section>
          );
        }
        return (
          <section className="clusters__size-cell">
            <article>
              <u>Analytics</u>
              <br />
              <label>{instance?.analyticsSize?.displayName}</label>
            </article>
            <article>
              <u>Loader</u>
              <br />
              <label>{instance?.loaderSize?.displayName}</label>
            </article>
          </section>
        );
      },
    },
    {
      title: 'More Information',
      render(_, instance) {
        return (
          <span>
            {instance.customBuild && (
              <Tag color="magenta">{instance.customBuild}</Tag>
            )}

            {instance.platform === 'gc' && <Tag color="cyan">Google</Tag>}
            {(instance.platform === 'az' ||
              instance.platform === 'az-saas') && <Tag color="cyan">Azure</Tag>}
            {instance.platform === 'aws' && <Tag color="cyan">Amazon</Tag>}

            {instance.installation && (
              <Tag color="orange">
                {instance.installation.charAt(0).toUpperCase() +
                  instance.installation.slice(1)}
              </Tag>
            )}

            {!instance.sleeppable && <Tag color="red">Always Alive</Tag>}
            {instance.sqliEnabled && <Tag color="green">SQLI Enabled</Tag>}
            {instance.incortaXEnabled && <Tag color="green">SQLX Enabled</Tag>}
            {instance.subClusters && instance.subClusters.length > 0 && (
              <Tag color="green">Self Managed Enabled</Tag>
            )}
            {instance.enableChat && <Tag color="blue">Chat Enabled</Tag>}
            {instance.isMigrated && <Tag color="blue">Migrate</Tag>}
            {instance.cname && <Tag color="purple">CName</Tag>}
            {instance.hostName && <Tag color="purple">Host</Tag>}
            {instance.enableOnboarding && (
              <Tag color="blue">Obboarding Enabled</Tag>
            )}
            {instance.allowBackup && <Tag color="blue">Daily Backup</Tag>}
          </span>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Deleted At',
      dataIndex: 'deletedAt',
      render(date) {
        return date === null
          ? ''
          : format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Actions',
      render(_, instance) {
        return (
          <Space>
            {!instance.deletedAt && (
              <Tooltip
                title={
                  !userHasEditClusterPagePermission
                    ? 'You don❜t have sufficient permission'
                    : 'Edit'
                }
              >
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setModalProps({ isNew: false, cluster: instance });
                  }}
                  disabled={!userHasEditClusterPagePermission}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}
            {!instance.deletedAt && (
              <Tooltip
                title={
                  !userHasEditClusterPagePermission
                    ? 'You don❜t have sufficient permission'
                    : 'Upgrade'
                }
              >
                <Button
                  size="small"
                  onClick={() => {
                    setUpgradeModalProps({ isNew: false, cluster: instance });
                  }}
                  disabled={!userHasEditClusterPagePermission}
                >
                  <CloudUploadOutlined />
                </Button>
              </Tooltip>
            )}
            {!instance.deletedAt && (
              <Tooltip
                title={
                  !userHasEditClusterPagePermission
                    ? 'You don❜t have sufficient permission'
                    : 'clone'
                }
              >
                <Button
                  size="small"
                  onClick={() => {
                    setCloneModalProps({ isNew: false, cluster: instance });
                  }}
                  disabled={!userHasEditClusterPagePermission}
                >
                  <DiffOutlined />
                </Button>
              </Tooltip>
            )}
            {!instance.deletedAt && (
              <Tooltip
                title={
                  !(userRolesActions.TRANSFER_CLUSTER in user.userRolesActions)
                    ? 'You don❜t have sufficient permission'
                    : 'transfer'
                }
              >
                <Button
                  size="small"
                  onClick={() => {
                    setTransferOwnershipModelProps({
                      isNew: false,
                      clusterIds: [instance.id],
                    });
                  }}
                  disabled={
                    !(
                      userRolesActions.TRANSFER_CLUSTER in user.userRolesActions
                    )
                  }
                >
                  <UserSwitchOutlined />
                </Button>
              </Tooltip>
            )}

            {!instance.deletedAt &&
              userRolesActions.BLOCK_CLUSTER_FROM_URL in
                user.userRolesActions && (
                <Tooltip title="URL Block">
                  <Button
                    size="small"
                    onClick={() => {
                      Modal.confirm({
                        title: (
                          <>
                            Are you sure you want to{' '}
                            {instance.blockFromUrl ? 'unblock' : 'block'}
                            {' starting this cluster '}
                            <strong>{instance.name}</strong> through accessing
                            the URL?
                          </>
                        ),
                        async onOk() {
                          await blockClusterFromUrl(
                            instance.name,
                            instance.blockFromUrl,
                          );
                          await refetchClusters();
                        },
                      });
                    }}
                  >
                    {instance.blockFromUrl ? (
                      <CheckOutlined />
                    ) : (
                      <StopOutlined />
                    )}
                  </Button>
                </Tooltip>
              )}

            {!instance.deletedAt && (
              <Tooltip
                title={
                  !userHasDeleteClusterPagePermission
                    ? 'You don❜t have sufficient permission'
                    : 'Delete'
                }
              >
                <Button
                  size="small"
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to delete this cluster
                          <strong>{instance.name}</strong>?
                        </>
                      ),
                      async onOk() {
                        await deleteCluster(instance.name);
                        await refetchClusters();
                      },
                    });
                  }}
                  disabled={!userHasDeleteClusterPagePermission}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            )}
            {!instance.deletedAt && (
              <Tooltip
                title={
                  !userHasEditClusterPagePermission
                    ? 'You don❜t have sufficient permission'
                    : instance.status === 'running'
                    ? 'Disconnect'
                    : 'Connect'
                }
              >
                <Button
                  size="small"
                  disabled={
                    instance.status === 'stopping' ||
                    !userHasEditClusterPagePermission
                  }
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to{' '}
                          {instance.status === 'running'
                            ? 'Disconnect'
                            : 'Connect'}
                          {' This Cluster '}
                          <strong>{instance.name}</strong>?
                        </>
                      ),
                      async onOk() {
                        instance.status === 'running'
                          ? await disconnectCluster(instance.name)
                          : await connectCluster(instance.name);
                        await refetchClusters();
                      },
                    });
                  }}
                >
                  {instance.status === 'running' ? (
                    <DisconnectOutlined />
                  ) : (
                    <CloudOutlined />
                  )}
                </Button>
              </Tooltip>
            )}
            {
              <Tooltip
                title={
                  !userHasEditClusterPagePermission
                    ? 'You don❜t have sufficient permission'
                    : 'SLA and Cost Options'
                }
              >
                <Button
                  size="small"
                  onClick={() => {
                    setSlaCostModalProps({ isNew: false, cluster: instance });
                  }}
                  disabled={!userHasEditClusterPagePermission}
                >
                  <AccountBookOutlined />
                </Button>
              </Tooltip>
            }
          </Space>
        );
      },
    },
  ];

  const searchPlaceholder =
    "Search By Cluster Name, Owner Email, Owner Name, Comapny Or 'Field Name'=Some Value. " +
    "Custom fields include 'deleted', 'installation', 'platform', 'alive'.";
  return (
    <div className="Clusters">
      <Title level={3}>Clusters</Title>

      <SearchableTable
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        searchPlaceholder={searchPlaceholder}
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={clusters}
        loading={isPending}
        columns={columns}
        advancedSearchColumns={searchColumns}
      />

      {modalProps && (
        <ClusterModal
          {...modalProps}
          refetchClusters={refetchClusters}
          close={() => setModalProps(null)}
        />
      )}
      {upgradeModalProps && (
        <UpgradeModal
          {...upgradeModalProps}
          refetchClusters={refetchClusters}
          close={() => setUpgradeModalProps(null)}
        />
      )}
      {cloneModalProps && (
        <CloneModal
          {...cloneModalProps}
          refetchClusters={refetchClusters}
          close={() => setCloneModalProps(null)}
        />
      )}
      {transferOwnershipModelProps && (
        <TransferOwnershipModel
          {...transferOwnershipModelProps}
          refetchClusters={refetchClusters}
          close={() => setTransferOwnershipModelProps(null)}
        />
      )}
      {slaCostModalProps && (
        <SlaCostModal
          {...slaCostModalProps}
          refetchClusters={refetchClusters}
          close={() => setSlaCostModalProps(null)}
        />
      )}
    </div>
  );
}

export default InstanceDetails;
