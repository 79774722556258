export enum LibraryInstallationStateEnum {
  'installing' = 'installing',
  'installed' = 'installed',
  'failed' = 'failed',
  'uninstalling' = 'uninstalling',
  'uninstalled' = 'uninstalled',
}

export enum LibraryType {
  PYPI = 'pypi',
  MAVEN = 'maven',
  CRAN = 'cran',
  FILE = 'file',
}

type LibrariesResponseItemCommon = {
  id: number;
  name: string;
  sourceColumn: string | null;
  instanceID: string;
  createdAt: string;
  updatedAt: string;
  analyticsState: LibraryInstallationStateEnum;
  loaderState: LibraryInstallationStateEnum;
  sparkState: LibraryInstallationStateEnum; // NEW
  chidoriState?: LibraryInstallationStateEnum | null;
  /** 
   * JSON for errors; keys can be `analytics-0` and if having other analytics, `analytics-0` and if having other loader, `spark`, `chidori`
   * @example 
   * ```
   * {
   *    "analytics-0": "ERROR: Invalid requirement: 'ueuw827!': Expected end or semicolon (after name and no valid version specifier)\n    ueuw827!\n           ^ (from line 2 of requirements.txt)\nFailure in analytics",
        "loader-0": "ERROR: Invalid requirement: 'ueuw827!': Expected end or semicolon (after name and no valid version specifier)\n    ueuw827!\n           ^ (from line 2 of requirements.txt)\nFailure in loader",
        "spark": "\u001b[0m\u001b[91mERROR: Invalid requirement: 'ueuw827!': Expected end or semicolon (after name and no valid version specifier)\n    ueuw827!\n"
   * }
   */
  errorDetails: Record<string, string> | null;
};
export type LibrariesResponse = { libraries: LibrariesResponseItem[] };
export type LibrariesResponseItem = LibrariesResponseItemCommon &
  (
    | PyPILibraryResponseData
    | MavenLibraryResponseData
    | CranLibraryResponseData
    | FileLibraryResponseData
  );

type Pypi = {
  id: string;
  analyticsState: LibraryInstallationStateEnum;
  loaderState: LibraryInstallationStateEnum;
  sparkState: LibraryInstallationStateEnum; // NEW
  chidoriState?: LibraryInstallationStateEnum;
  type: LibraryType.PYPI;
  data: PyPIData;
};

type PyPILibraryResponseData = {
  type: LibraryType.PYPI;
  data: PyPIData;
};
export type PyPIData = {
  package: string; // comma separated
};

type GenericLibrary<T> = LibrariesResponseItemCommon & T;

export type PyPIItem = GenericLibrary<PyPILibraryResponseData>;

type MavenLibraryResponseData = {
  type: LibraryType.MAVEN;
  data: MavenData;
};
export type MavenData = {
  coordinates: string;
  repository?: string; // optional
  // exclusions: string;
};

type CranLibraryResponseData = {
  type: LibraryType.CRAN;
  data: CranData;
};
export type CranData = {
  package: string;
  repository?: string;
};

type FileLibraryResponseData = {
  type: LibraryType.FILE;
  data: FileData;
};
export type FileDataItem = {
  organization?: string | null;
  file: string; //
};
export type FileData = FileDataItem;

export type InstallLibraryData = InstallLibraryDataItem[];
export type InstallLibraryDataItem =
  | PyPIInstallLibraryData
  | MavenInstallLibraryData
  | CranInstallLibraryData
  | FileInstallLibraryData;
type PyPIInstallLibraryData = {
  type: LibraryType.PYPI;
  data: {
    package: string;
  };
};
type MavenInstallLibraryData = {
  type: LibraryType.MAVEN;
  data: {
    coordinates: string;
    repository?: string;
    exclusions: string;
  };
};
type CranInstallLibraryData = {
  type: LibraryType.CRAN;
  data: {
    package: string;
    repository?: string;
  };
};
type FileInstallLibraryData = {
  type: LibraryType.FILE;
  data: {
    organization: string; // organization name: from /me
    file: string; //
  }[];
};

export type LibraryDataTypeMap = {
  [LibraryType.PYPI]: {
    packages: string;
  };
  [LibraryType.MAVEN]: {
    coordinates: string;
    repository?: string;
  };
  [LibraryType.CRAN]: {
    package: string;
    repository?: string;
  };
  [LibraryType.FILE]: {
    file: string;
  };
};

// export type LibraryDataUnInstallMap = {
//   [LibraryType.PYPI]: ;
//   [LibraryType.MAVEN]: ;
//   [LibraryType.CRAN]: ;
//   [LibraryType.FILE]: ;
// };

export type InstallLibraryResponse = {
  message: string;
};

export type UnInstallLibraryData = UnInstallLibraryDataItem[];
export type UnInstallLibraryDataItem =
  | PyPIUnInstallLibraryData
  | MavenUnInstallLibraryData
  | CranUnInstallLibraryData
  | FileUnInstallLibraryData;
type PyPIUnInstallLibraryData = {
  type: LibraryType.PYPI;
  data: {
    packages: string; // comma separated
  };
};
type MavenUnInstallLibraryData = {
  type: LibraryType.MAVEN;
  data: {
    coordinates: string;
    repository?: string;
  }[];
};
type CranUnInstallLibraryData = {
  type: LibraryType.CRAN;
  data: {
    package: string;
    repository?: string;
  }[];
};
type FileUnInstallLibraryData = {
  type: LibraryType.FILE;
  data: {
    // organization: string; // organization name: from /me
    file: string; //
  };
};
export type UnInstallLibraryResponse = {
  message: string;
};

export type UnInstallMultipleLibraryResponse = {
  message: string;
};

export type UploadFileResponse = {
  uploadURL: string;
};
