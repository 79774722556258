import React, { useState } from 'react';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { fieldNames } from 'components/guidance/CloudCapacityManagement/ClusterSizeCellModal/ClusterSizeCellModal';

function ClusterSizeOvercommitmentStep({
  isCreateNewSize,
  form,
  initialValues,
}: {
  isCreateNewSize: boolean;
  form?: FormInstance<any>; // FIXME:
  initialValues?: any;
}) {
  if (
    (!isCreateNewSize || !!Object.keys(initialValues).length) &&
    'overCommitment' in initialValues
  ) {
    initialValues.overCommitment.isEnabled =
      !initialValues.overCommitment.excluded;
  }
  const [isOvercommitmentDisabled, setIsOvercommitmentDisabled] = useState(
    isCreateNewSize ? true : initialValues.overCommitment.excluded,
  );

  const overcommitmentEnablementRule = ({ getFieldValue }) => ({
    validator(_, value) {
      if (getFieldValue(fieldNames.overCommitmentEnabled) && !value) {
        return Promise.reject(
          new Error('Field is required because Overcommitment is enabled'),
        );
      }
      return Promise.resolve();
    },
  });
  return (
    <Form
      name="ClusterSizeOvercommitmentStep"
      initialValues={initialValues}
      form={form}
    >
      <FormItem
        name={fieldNames.overCommitmentEnabled}
        valuePropName="checked"
        label="Enable"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
      >
        <Checkbox
          // value={isOvercommitmentDisabled}
          onChange={e => {
            setIsOvercommitmentDisabled(!e.target.checked);
          }}
        />
      </FormItem>

      <FormItem
        name={fieldNames.overCommitmentAnalyticsValue}
        label="Analytics Value"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        required={!isOvercommitmentDisabled}
        rules={[overcommitmentEnablementRule]}
      >
        <InputNumber min={1} disabled={isOvercommitmentDisabled} />
      </FormItem>

      <FormItem
        name={fieldNames.overCommitmentLoaderValue}
        label="Loader Value"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        required={!isOvercommitmentDisabled}
        rules={[overcommitmentEnablementRule]}
      >
        <InputNumber min={1} disabled={isOvercommitmentDisabled} />
      </FormItem>
    </Form>
  );
}

export default ClusterSizeOvercommitmentStep;
