const en = {
  general: {
    clusterMustBeConnectedToChange:
      'Your cluster must be connected to apply this change',
  },
  systemManagement: {
    toggleFeatures: {
      azureSupport: {
        userModal: {
          title: 'Edit Azure Toggle Feature for User',
          azurePlansDdlLabel: 'Azure clusters plan',
          azurePlansDdlPlaceholder: 'Please select a plan',
          updateBtn: 'Update',
          cancelBtn: 'Cancel',
          freeUserHint: ' Can’t add Azure Plan for Free users',
        },
      },
    },
  },
  userRolesPage: {
    pageTitle: 'User Roles',
    createNewRoleButton: 'Create new role',
    userRolesTable: {
      fields: { role: 'Role', action: 'Action' },
      buttons: {
        edit: 'Edit Role',
        delete: 'Delete',
        deleteDisabled: 'This role cannot be deleted',
      },
    },
    userRoleModal: {
      gettingUserData: 'Getting User Roles',
      title: {
        newRole: 'New Role',
        editRole: 'Edit Role',
      },
      fields: {
        name: 'Name',
        actions: 'Actions',
        pages: 'Pages',
        pagesHint: {
          title: 'Permissions: ',
          view: '• View: Allows the user to see the content.',
          edit: '• Edit: Grants the ability to modify and view the content.',
          delete:
            '• Delete: Provides full control, including viewing, editing, and deleting content.',
        },
      },
      pagesTable: {
        fields: { page: 'Page', permission: 'Permission' },
        buttons: {
          permission: {
            view: 'View',
            edit: 'Edit',
            delete: 'Delete',
          },
          remove: 'Remove Page',
        },
      },
      requiredPagesTooltip: 'Required pages to add',
      recommendationTooltip: 'Recommended pages to add',
      addPage: 'Add a page',
      buttons: {
        create: 'Create',
        update: 'Update',
        cancel: 'Cancel',
      },
      shouldSelectActionOrPage: 'You must select at least one page',
      reviseRequiredPagesToAdd:
        'Please add all the required pages for each action',
      somethingWentWrong: 'Something Went Wrong',
    },
  },
  clusterConfigurations: {
    autoscaling: {
      wholeNumberValidation: 'Please enter a whole number',
      title: ' Auto Scaling ',
      requestDelay: 'Request Delay (sec)',
      coolDelay: 'Cooldown delay (min)',
      rejectedQueriesUp: 'Rejected queries upscale',
      rejectedQueriesDown: 'Rejected queries downscale',
      consecutiveQueries: 'Consecutive queries',
      status: 'Status: ',
      enabled: 'Enabled',
      disabled: 'Disabled',
      changeAutoscalingHint:
        'Any change will be reflected after enabling the autoscaling',
      hints: {
        requestDelay: 'Time interval between each query [{min} - {max}]',
        coolDelay: 'Delay between each scaling process [{min} - {max}]',
        rejectedQueriesUp:
          'Limit of rejected queries count before scaling up [{min} - {max}]',
        rejectedQueriesDown:
          'Limit of rejected queries count before scaling down [{min} - {max}]',
        consecutiveQueries:
          'Minimum amount of sequential queries before scaling up or down [{min} - {max}]',
      },
    },
  },
  cloudCapacityManagement: {
    pageTitle: 'Cloud Capacity Management',
    tabs: {
      gc: 'Google Cloud',
      azure: 'Azure',
      aws: 'AWS',
    },
    createNewSizeButton: 'Create New Size',
    newSizeModal: {
      title: 'Create New Size',
    },
    sizeModal: {
      title: 'Size "{displayName}" in "{operator}"',
      titleNewSize: 'Create New Size',
    },
    deleteConfirmModal: {
      title: 'Delete Size',
      content:
        'Are you sure you want to delete size {clusterSize} in {operator}?',
      okText: 'Yes',
      cancel: 'Cancel',
    },
  },
  pythonPackagesTag: {
    installing: 'installing',
    failed: 'failed',
    succeeded: 'succeeded',
  },
  clusterPage: {
    createNewCluster: 'Create New Cluster',
    createConsentFlow: 'Configure Azure clusters',
    displayVersions: 'Sync Versions',
    externalBucket: 'External Bucket',
  },
  clusterActions: {
    externalBucket: {
      title: 'External Bucket of cluster <strong>{clusterName}</strong>',
      destinationClusterAlert:
        'Cluster <strong>{clusterName}</strong> is a <i>Destination</i> for <strong>{isClusterADestination}</strong>',
      destinationClusterAlertDescription:
        'You can remove this cluster for being a destination through its source cluster <strong>{isClusterADestination}</strong>',
      dataExtraction: 'Data Extraction: ',
      bucketPath: 'Bucket Path: ',
      copyBucketPath: 'Copy Bucket Path',
      clustersInSameBucket: 'Clusters in the same bucket: ',
      searchClusters: 'Search Clusters',
      addClusterToExternalBucket:
        'Add Cluster to External Bucket as Destination',
      addClusterToExternalBucketButton: 'Add',
      clustersTable: {
        clusterColumn: 'Cluster',
        isDestinationClusterEnabledColumn: 'Enabled',
        removeClusterFromExternalBucketTooltip:
          'Remove Cluster From External Bucket',
        removeClusterFromExternalBucketButton: 'Remove',
      },
    },
  },
  partnersPage: {
    pageTitle: 'IncortaOne Partners',
    createNewPartnerButton: 'Create new IncortaOne Partner',
    partnersTable: {
      fields: {
        name: 'Partner Name',
        email: 'Email',
        clustersCount: 'Clusters Count',
        platform: 'Platforms',
        clustersSize: 'Clusters Size',
      },
    },
    partnersModal: {
      gettingPartnersData: 'Getting IncortaOne Partners',
      title: {
        newPartner: 'New IncortaOne Partner',
        editPartner: 'Edit Partner',
      },
      fields: {
        name: 'Name',
        partnerEmail: 'Email',
        clustersCount: 'Clusters Count',
        maxCPU: 'Max CPU / Cluster',
        maxMemory: 'Max Memory / Cluster',
        platforms: 'Platform',
        supportedRegions: 'Regions',
        clustersDefaultSize: 'Clusters Default Size',
        allowSqlX: 'SQL X',
        allowMLflow: 'MLflow',
      },
      buttons: {
        create: 'Create',
        update: 'Update',
        cancel: 'Cancel',
      },
      somethingWentWrong: 'Something Went Wrong',
    },
  },
  knowIncortaBanner: {
    incortaCommunity: 'Incorta Community',
    join: 'Join',
    incortaOverview: 'Incorta Overview Demo',
    watch: 'Watch',
    incortaDocumentation: 'Incorta Documentation',
    freeTraining: 'Free Training',
    start: 'Start',
  },
  clusterServerConfigurationList: {
    title: 'SQL Interface for External BI Tools',
  },
  clustersPage: {
    clusterTypes: {
      incortaCluster: 'Incorta cluster',
      azureCluster: 'Azure cluster',
    },
  },
  consentFlow: {
    formTitle: 'Configure your Azure clusters',
    formSubtitle: "Only one step and you're good to go!",
    envName: 'Environment Name',
    envNamePlaceholder: 'Incorta',
    envNameTooltip:
      "We'll create two clusters (environments) based on this name—one for development and one for production (e.g., Incorta-DEV and Incorta-PROD).",
    envNameRequired: 'Please enter a valid environment name.',
    envNameMinimumCount: 'Name must be at least 3 characters.',
    envNameMaximmumCount: 'Name cannot be longer than 8 characters.',
    azureSubID: 'Azure Subscription ID',
    azureSubIDPlaceholder: '00000000-0000-0000-0000-000000000000',
    azureRG: 'Azure Resource Group Name',
    azureRGPlaceholder: 'Resource Group Name',
    azureRGTooltip:
      'Incorta recommends creating the resource group in the same location as the resources region.',
    resourceGroupRequired: 'Please enter your Azure Resource Group Name.',
    resourceRegionSelection: 'Resources Region Selection',
    resourceRegionSelectionPlaceholder: 'Select',
    subIDRequired: 'Please enter your Azure Subscription ID.',
    subIDValidRequired:
      'Please enter a valid subscription ID. It must match the format 00000000-0000-0000-0000-000000000000 and it only accepts alphanumeric characters.',
    subCredRequired: 'Please enter Azure Subscription Information Output.',
    azureSubscriptionInfoOutputPlaceholder: 'Paste output here',
    resourceRegionSelectionRequired: 'Please select a Resource Region.',
    azureCloudShell: 'Azure Cloud Shell',
    commandRequiredGoTo: 'Go to ',
    commandRequiredRun: ' and run the following command: ',
    commandRequiredTooltip:
      'Copy the following command and run it on {azureCloudShell}, then paste the output under Azure subscription information.',
    spCommand: `az ad sp create-for-rbac --name IncortaSP-<Environment name> --role owner --scopes /subscriptions/<subscription_id>/resourceGroups/<resourcegp_name>`,
    azureCommandCopyTooltip: 'Copy Command',
    azureCommandCantCopyTooltip:
      'In order to copy, please make sure to enter a valid environment name, subscription ID, and resource group name.',
    azureCommandCopySuccessful: 'Azure Command has been copied successfully.',
    networks: 'Networks',
    networksTooltip:
      'Use one of the existing networks you have, or let Incorta provide a new network.',
    newNetwork: 'New Network',
    existingNetwork: 'Existing Network',
    networkName: 'Network Name',
    networkNameTooltip: 'Provide the name of your existing virtual network',
    networkNameRequired: 'Please enter a Network Name.',
    addYourNetworkSubnets: 'Add your Network Subnets',
    subnets: {
      subnetRequiredAsterisk: '* ',
      incortaSubnet: 'Incorta Subnet',
      subnetPlaceholderPrefix: 'Example: ',
      incortaSubnetPlaceholder: '172.16.0.0/14',
      sparkSubnet: 'Spark Subnet',
      sparkSubnetPlaceholder: '172.20.0.0/14',
      publicSubnet: 'Public Subnet',
      publicSubnetPlaceholder: '172.24.0.0/24',
      databaseSubnet: 'Database Subnet',
      databaseSubnetPlaceholder: '172.24.1.0/24',
      loadbalancerSubnet: 'Load Balancer Subnet',
      loadbalancerSubnetPlaceholder: '172.24.2.0/24',
    },
    networkSubnetsRequired: 'Please complete the Network Subnets',
    networkConnectionSuccessful: 'Network Connection Test successful',
    networkConnectionFailed: 'Network Connection Test failed',
    cancelButton: 'Cancel',
    submitButton: 'Submit',
    submittingStatus: {
      validating: 'Validating...',
      validationSuccessful: 'Validation successful',
    },
    submissionSuccess:
      'Deploying Incorta on your Azure subscription has started. Please wait 45-50 minutes for your clusters creation.',
    submissionError: 'Something went wrong. Please try again.',
  },
  clusterForm: {
    clusterName: 'Cluster Name',
    clusterNameHint:
      'Cluster name can be a minimum of 3 characters long (Alphabet english letters, numbers, dashes or combination), no spaces are allowed, can’t start with numbers and can’t begin or end with dash. Example: incorta-101.',
    clusterNameRequired: 'Please input your Cluster Name!',
    clusterNamePlaceholder: 'Cluster Name',
    clusterDiskSizeLabel: 'Disk Size',
    clusterDiskSizeRequired: 'Please input your Disk Size',
    clusterDiskSizePlaceholder: 'Disk Size',
    clusterSizeLabel: 'Cluster Size',
    clusterSizeRequired: 'Please select your Size!',
    clusterSizeExtra: `IPU refers to Incorta Power Units. To request an increase for these limits. {supportLink}.`,
    clusterVersionLabel: `Incorta Version`,
    clusterRegionLabel: `Cluster Region`,
    clusterVersionRequired: 'Please select your Incorta Version!',
    clusterRegionRequired: 'Please select your Cluster Region!',
    clusterVersionPlaceholder: 'Select your Incorta Version',
    clusterRegionPlaceholder: 'Select your Cluster Region',
    clusterTenantLabel: 'Tenant Content',
    clusterTenantRequired: 'Please select your Tenant Content!',
    clusterTenantPlaceholder: 'Select your Tenant Content',
    clusterInstallationLabel: 'Installation',
    clusterInstallationRequired: 'Please select Incorta installation type!',
    clusterInstallationPlaceholder: 'Select your Installation',
    clusterAliveLabelTitle: 'Keep alive',
    clusterAliveLabelHint:
      'After a period of inactivity the cluster will sleep, turn on to keep it alive.',
    clusterPythonPackagesLabel: 'REQUIRED PYTHON PACKAGES',
    clusterPythonPackagesExtra:
      'Should only contain Python packages which are compatible with pip.',
    clusterPythonPackagesPlaceholder: '"Ex: pandas, numpy"',
    clusterCreateButton: 'Create',
    clusteCancelButton: 'Cancel',
  },
  region: {
    'asia-east1': 'Asia-East1 (Taiwan)',
    'asia-east2': 'Asia-East2 (Hong Kong)',
    'asia-northeast1': 'Asia-Northeast1 (Tokyo)',
    'asia-northeast2': 'Asia-Northeast2 (Osaka)',
    'asia-northeast3': 'Asia-Northeast3 (Seoul)',
    'asia-south1': 'Asia-South1 (Mumbai)',
    'asia-south2': 'Asia-South2 (Delhi)',
    'asia-southeast1': 'Asia-Southeast1 (Singapore)',
    'asia-southeast2': 'Asia-Southeast2 (Jakarta)',
    'australia-southeast1': 'Australia-Southeast1 (Sydney)',
    'australia-southeast2': 'Australia-Southeast2 (Melbourne)',
    'europe-central2': 'Europe-Central2 (Warsaw)',
    'europe-north1': 'Europe-North1 (Finland)',
    'europe-west1': 'Europe-West1 (Belgium)',
    'europe-west2': 'Europe-West2 (London)',
    'europe-west3': 'Europe-West3 (Frankfurt)',
    'europe-west4': 'Europe-West4 (Netherlands)',
    'europe-west6': 'Europe-West6 (Zurich)',
    'northamerica-northeast1': 'Northamerica-Northeast1 (Montréal)',
    'northamerica-northeast2': 'Northamerica-Northeast2 (Toronto)',
    'southamerica-east1': 'Southamerica-East1 (São Paulo)',
    'us-central1': 'US-Central1 (Iowa)',
    'us-east1': 'US-East1 (South Carolina)',
    'us-east4': 'US-East4 (N. Virginia)',
    'us-west1': 'US-West1 (Oregon)',
    'us-west2': 'US-West2 (Los Angeles)',
    'us-west3': 'US-West3 (Salt Lake City)',
    'us-west4': 'US-West4 (Las Vegas)',
  },
  auth0: {
    emailSent: {
      pageTitle: 'Check your inbox!',
      matchingEmail:
        'We just sent you an email to verify your email address. Select the button in the email, and you’re all set!',
      cantFindEmail: 'Can’t find it? ',
      resendEmail: 'Resend the email',
      or: ' or ',
      contactSupport: 'Contact incorta Support',
      returnToHomePage: 'Return to Homepage',
    },
    errorPages: {
      general: {
        returnToLoginPageButton: 'Return back to Login Page',
      },
      blockedUser: {
        headerLine1: 'Your account is blocked!',
        headerLine2: ' ',
        contactSupport: 'Contact us',
        subheader: '  to unblock it.',
      },
      trialExpired: {
        headerLine1: 'Your Trial Expired!',
        headerLine2: 'Want to Extend It?',
        contactSupport: 'Contact Support',
        subheader: ' to extend your trial and explore Incorta further.',
      },
      guestUser: {
        headerLine1:
          "You don't have permission to login to the administration portal.",
        headerLine2: ' ',
        contactSupport: 'Contact us',
        subheader: '  to gain access.',
      },
      mfaRequired: {
        headerLine1: 'Multifactor authentication required',
      },
    },
  },
  rolesTag: {
    customRoles: 'Custom Roles',
    multiRoles: 'Multi Roles',
  },
  dynamicAdvancedSearch: {
    searchFieldKey: 'Search By',
    searchFieldValue: 'Value',
    removeSearch: 'Remove',
    addSearch: 'Add',
    disabledBtnTooltip: 'Fill all the search fields',
  },
};
export default en;
