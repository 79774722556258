import './CreateClustersProvisionPanel.less';
import { Button, Collapse } from 'antd';
import CollapseHeader from 'components/NewProvisionCustomer/components/CollapseHeader/CollapseHeader';
import ClusterHeaderActions from 'components/NewProvisionCustomer/components/headerActions/ClusterHeaderActions/ClusterHeaderActions';
import ClusterForm from 'components/NewProvisionCustomer/components/ClusterForm/ClusterForm';

function CreateClustersProvisionPanel({
  clustersNameCount,
  addNewCluster,
  removeCluster,
  clusterSizes,
  provisionOptionsIsPending,
  provisionOptions,
  activeKey,
  setActiveKey,
  form,
  clusters,
  mapClusterSize,
}) {
  const { Panel } = Collapse;

  return (
    <div
      className="create-clusters-provision-panel NewProvisionCustomer__panel-wrapper"
      style={{ maxWidth: '550px', padding: '10px  0' }}
    >
      <Collapse
        ghost
        bordered={false}
        className="InnerCollapse"
        collapsible="header"
        expandIcon={() => null}
        activeKey={activeKey}
        defaultActiveKey="0"
        onChange={keys => setActiveKey(keys)}
      >
        <Panel
          forceRender
          header={
            <CollapseHeader
              activeKey={activeKey}
              panelKey="1"
              text={clustersNameCount[0]}
            >
              <ClusterHeaderActions
                activeKey={activeKey}
                panelKey="1"
                remove={() => {}}
              />
            </CollapseHeader>
          }
          key="1"
        >
          <ClusterForm
            index={0}
            provisionOptionsIsPending={provisionOptionsIsPending}
            provisionOptions={provisionOptions}
            form={form}
            clusters={clusters}
          />
        </Panel>
        {clustersNameCount?.length > 1 &&
          clustersNameCount.slice(1).map((cluster, index) => (
            <Panel
              key={(+index + 2).toString()}
              forceRender
              header={
                <CollapseHeader
                  activeKey={activeKey}
                  panelKey={(+index + 2).toString()}
                  text={clustersNameCount[index + 1]}
                >
                  <ClusterHeaderActions
                    activeKey={activeKey}
                    panelKey={(+index + 2).toString()}
                    isDeletable
                    remove={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeCluster(cluster, (+index + 2).toString());
                    }}
                  />
                </CollapseHeader>
              }
            >
              <ClusterForm
                index={index + 1}
                provisionOptionsIsPending={provisionOptionsIsPending}
                provisionOptions={provisionOptions}
                form={form}
                clusters={clusters}
              />
            </Panel>
          ))}
        <div className="create-clusters__add-new-button">
          <Button onClick={addNewCluster}>Add new cluster</Button>
        </div>
      </Collapse>
    </div>
  );
}

export default CreateClustersProvisionPanel;
