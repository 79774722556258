import React, { useRef, useState } from 'react';
import './NodeLabelsRenderer.less';
import { EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputRef, Select, Tag } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { NodeLabelObj } from 'types/capacityManagement.types';

const defaultNodeLabels: NodeLabelObj = {
  analytics: '',
  loader: '',
} as const;
const otherColors = ['magenta', 'orange', 'green', 'purple', 'red'] as const;

function NodeLabelTagInfo({
  nodeLblKey,
  nodeLblValue,
  onEditClick,
  isNodeLabelSelected = false,
}: {
  nodeLblKey: string;
  nodeLblValue: string;
  onEditClick: ({
    nodeLblKey,
    nodeLblValue,
  }: {
    nodeLblKey: string;
    nodeLblValue: string;
  }) => void;
  isNodeLabelSelected?: boolean;
}) {
  return (
    <section
      className={`node-labels-renderer__tag-label-wrapper${
        isNodeLabelSelected ? ' selected' : ''
      }`}
    >
      <article className="node-labels-renderer__tag-label-info">
        <strong>{nodeLblKey}</strong>
        <br />
        {nodeLblValue}
      </article>

      <Button
        type="text"
        icon={<EditOutlined />}
        size="small"
        className="node-labels-renderer__tag-label-btn"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          onEditClick({ nodeLblKey, nodeLblValue });
        }}
      />
    </section>
  );
}

function TagRenderer({ props }: { props: CustomTagProps }) {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const tagColor =
    value === 'analytics'
      ? 'geekblue'
      : value === 'loader'
      ? 'green'
      : otherColors[1];

  return (
    <Tag
      color={tagColor}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}

function NodeLabelsRenderer({
  currentNodeLabelRow,
  onNodeLabelsChangeForm,
}: {
  currentNodeLabelRow: {
    label: NodeLabelObj;
  };
  onNodeLabelsChangeForm: ({
    selectedNodeLabels,
  }: {
    selectedNodeLabels: Record<string, string>;
  }) => void;
}) {
  if (!currentNodeLabelRow.label) {
    currentNodeLabelRow.label = defaultNodeLabels;
  }
  enum NodeLabelsChangeFormMode {
    'ADD' = 'ADD',
    'EDIT' = 'EDIT',
  }
  const [mode, setMode] = useState<NodeLabelsChangeFormMode>(
    NodeLabelsChangeFormMode.ADD,
  );

  const [nodeLabelForm] = Form.useForm();
  const fieldNames = {
    nodeLabelKey: 'nodeLabelKey',
    nodeLabelValue: 'nodeLabelValue',
  } as const;

  function resetModeAndUI() {
    setMode(NodeLabelsChangeFormMode.ADD);
    setNodeLabelKey('');
    setNodeLabelValue('');

    setselectedEditNodeLabel('');

    nodeLabelForm.setFieldsValue({
      [fieldNames.nodeLabelKey]: '',
      [fieldNames.nodeLabelValue]: '',
    });
  }
  type OnEditClickProps = {
    nodeLblKey: string;
    nodeLblValue: string;
  };
  function onEditClick({ nodeLblKey, nodeLblValue }: OnEditClickProps) {
    if (mode === NodeLabelsChangeFormMode.EDIT) {
      resetModeAndUI();
    } else {
      setMode(NodeLabelsChangeFormMode.EDIT);
      setNodeLabelKey(nodeLblKey);
      setNodeLabelValue(nodeLblValue);

      setselectedEditNodeLabel(nodeLblKey);

      nodeLabelForm.setFieldsValue({
        [fieldNames.nodeLabelKey]: nodeLblKey,
        [fieldNames.nodeLabelValue]: nodeLblValue,
      });
    }
  }

  const [selectedEditNodeLabel, setselectedEditNodeLabel] = useState('');

  interface Item extends DefaultOptionType {
    nodeLabelValue: string;
    controledLabel: (
      isNodeLabelSelected: boolean,
      onEditClick: ({ nodeLblKey, nodeLblValue }: OnEditClickProps) => void,
    ) => JSX.Element;
  }
  let itemsRef: Item[] = Object.entries(currentNodeLabelRow.label).map(
    ([nodeLblKey, nodeLblValue]) => ({
      value: nodeLblKey,
      controledLabel: (
        isNodeLabelSelected: boolean,
        onEditClick: ({ nodeLblKey, nodeLblValue }: OnEditClickProps) => void,
      ) => {
        return (
          <NodeLabelTagInfo
            key={nodeLblKey}
            nodeLblKey={nodeLblKey}
            nodeLblValue={nodeLblValue}
            onEditClick={onEditClick}
            isNodeLabelSelected={isNodeLabelSelected}
          />
        );
      },
      label: null,
      nodeLabelValue: nodeLblValue,
      ...(['analytics', 'loader'].includes(nodeLblKey)
        ? {
            disabled: true,
          }
        : {}),
    }),
  );

  const [nodeLabelKey, setNodeLabelKey] = useState('');
  const nodeLabelKeyRef = useRef<InputRef>(null);
  const [nodeLabelValue, setNodeLabelValue] = useState('');

  const handleSelectChange = (selectValues: string[]) => {
    const updatedItems = itemsRef.filter(item =>
      selectValues.includes(item.value.toString()),
    );
    itemsRef = updatedItems;

    const selectedNodeLabels: Record<string, string> = {};
    updatedItems.forEach(({ value: key, nodeLabelValue: val }) => {
      selectedNodeLabels[key] = val;
    });
    onNodeLabelsChangeForm({
      selectedNodeLabels,
    });
  };

  function onNodeLabelsFormValuesChange(
    changedValues: Record<'nodeLabelKey' | 'nodeLabelValue', string>,
    allValues: any,
  ) {
    if ('nodeLabelKey' in changedValues) {
      setNodeLabelKey(changedValues['nodeLabelKey']);
    } else if ('nodeLabelValue' in changedValues) {
      setNodeLabelValue(changedValues['nodeLabelValue']);
    }
  }
  function onNodeLabelsFormFinish({
    nodeLabelKey,
    nodeLabelValue,
  }: {
    nodeLabelKey: string;
    nodeLabelValue: string;
  }) {
    if (mode === NodeLabelsChangeFormMode.ADD) {
      itemsRef = [
        ...itemsRef,
        {
          controledLabel: (
            isNodeLabelSelected: boolean,
            onEditClick: ({
              nodeLblKey,
              nodeLblValue,
            }: OnEditClickProps) => void,
          ) => (
            <NodeLabelTagInfo
              nodeLblKey={nodeLabelKey}
              nodeLblValue={nodeLabelValue}
              onEditClick={onEditClick}
              isNodeLabelSelected={isNodeLabelSelected}
            />
          ),
          label: (
            <NodeLabelTagInfo
              nodeLblKey={nodeLabelKey}
              nodeLblValue={nodeLabelValue}
              onEditClick={onEditClick}
            />
          ),
          value: nodeLabelKey,
          nodeLabelValue: nodeLabelValue,
        },
      ];

      onNodeLabelsChangeForm({
        selectedNodeLabels: {
          ...currentNodeLabelRow.label,
          [nodeLabelKey]: nodeLabelValue,
        },
      });

      setNodeLabelKey('');
      setNodeLabelValue('');
      nodeLabelForm.setFieldsValue({
        [fieldNames.nodeLabelKey]: '',
        [fieldNames.nodeLabelValue]: '',
      });

      setTimeout(() => {
        nodeLabelKeyRef.current?.focus();
      }, 0);
    } else {
      const selectedNodeLabels: Record<string, string> = {};
      const modifiedItems = itemsRef.map(item => {
        if (item.value === nodeLabelKey) {
          item.value = nodeLabelKey;
          item.nodeLabelValue = nodeLabelValue;
        }
        selectedNodeLabels[item.value] = item.nodeLabelValue;
        return item;
      });
      itemsRef = modifiedItems;

      onNodeLabelsChangeForm({
        selectedNodeLabels,
      });
      resetModeAndUI();
      setDropdownOpen(false); // Close the dropdown after the button is clicked
    }
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Select
      mode="multiple"
      showArrow
      tagRender={props => {
        return <TagRenderer props={props} />;
      }}
      value={itemsRef.map(item => item.value)}
      onChange={handleSelectChange}
      style={{ width: '100%' }}
      open={dropdownOpen} // Control dropdown visibility
      onDropdownVisibleChange={open => setDropdownOpen(open)} // Update the state on dropdown visibility change
      options={itemsRef.map(item => ({
        label: item.controledLabel(
          item.value === selectedEditNodeLabel,
          onEditClick,
        ),
        value: item.value,
        disabled: item.disabled,
      }))}
      maxTagCount={'responsive'}
      dropdownStyle={{ width: '360px', minWidth: '360px' }}
      className="node-labels-renderer__dropdown-select"
      popupClassName="node-labels-renderer__dropdown-popup"
      dropdownRender={menu => (
        <section className="node-labels-renderer__dropdown-wrapper">
          {menu}
          <section className="node-labels-renderer__dropdown">
            <Form
              name="node-labels-form"
              form={nodeLabelForm}
              onFinish={onNodeLabelsFormFinish}
              onValuesChange={onNodeLabelsFormValuesChange}
            >
              <Form.Item
                name={fieldNames.nodeLabelKey}
                rules={[
                  { required: true, message: 'Please input a label!' },
                  {
                    validator(rule, value) {
                      if (
                        mode === NodeLabelsChangeFormMode.ADD &&
                        // value in nodeLabelObj
                        itemsRef.findIndex(item => item.value === value) !== -1
                      ) {
                        return Promise.reject(
                          new Error('Label already exists'),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Please enter label"
                  ref={nodeLabelKeyRef}
                  disabled={['analytics', 'loader'].includes(nodeLabelKey)}
                />
              </Form.Item>

              <Form.Item
                name={fieldNames.nodeLabelValue}
                rules={[{ required: true, message: 'Please input a value!' }]}
              >
                <Input
                  placeholder="Please enter value"
                  value={nodeLabelValue}
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                icon={
                  mode === NodeLabelsChangeFormMode.EDIT ? (
                    <SaveOutlined />
                  ) : (
                    <PlusOutlined />
                  )
                }
              >
                {mode === NodeLabelsChangeFormMode.EDIT ? 'Save' : 'Add'}
                {` Node Label`}
              </Button>
            </Form>
          </section>
        </section>
      )}
    />
  );
}

export default NodeLabelsRenderer;
