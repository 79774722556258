import './MaintenanceSchedulerField.less';
import React, { useState } from 'react';
import { Select, DatePicker, Form, Radio } from 'antd';
import moment from 'moment';
import { getWeekNumberAndDay } from '../../../utils/backup';

let { Option } = Select;

function MaintenanceSchedulerField({ name, label }) {
  const [disabled, setDisabled] = useState(true);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedWeekNumberAndDay, setSelectedWeekNumberAndDay] = useState('');

  const setDate = date => {
    let weekDayName = moment(date).format('dddd');
    setSelectedDay(weekDayName);
    setSelectedWeekNumberAndDay(getWeekNumberAndDay(moment(date)));
  };

  const toggle = e => {
    setDisabled(!e.target.value);
  };

  function disabledDate(current) {
    return current < moment().startOf('day');
  }

  return (
    <Form.Item name={name} label={label}>
      <Radio.Group onChange={toggle} defaultValue={false}>
        <Radio value={false}>Maintenance Now</Radio>
        <Radio value={true}>Scheduled Maintenance</Radio>
      </Radio.Group>

      {!disabled && (
        <Form.Item name="date" label="Start Date" rules={[{ required: true }]}>
          <DatePicker
            suffixIcon={<span>GMT</span>}
            style={{ width: '100%' }}
            format="YYYY-MM-DD HH:mm:ss"
            disabledDate={disabledDate}
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            onChange={(value, date) => setDate(date)}
          />
        </Form.Item>
      )}
    </Form.Item>
  );
}

export default MaintenanceSchedulerField;
