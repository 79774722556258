import { useContext, useEffect } from 'react';
import _ from 'lodash';
import { Space, Modal, Dropdown, MenuProps } from 'antd';
import {
  DeleteOutlined,
  CloudOutlined,
  CheckOutlined,
  StopOutlined,
  RobotOutlined,
  DisconnectOutlined,
  UserSwitchOutlined,
  MoreOutlined,
  CloseOutlined,
  RetweetOutlined,
  LineChartOutlined,
  LockOutlined,
  CrownOutlined,
  MehOutlined,
} from '@ant-design/icons';
import {
  deleteMultiCluster,
  blockMultiClusterFromUrl,
  disconnectMultiCluster,
  connectMultiCluster,
  refreshScriptsMultiCluster,
  collectMetricsMultiCluster,
  unblockMultiClusterFromUrl,
  upgradeToPremiumPackageBulk,
  removePremiumPackageBulk,
} from 'services/cluster';
import { multiclusterAction } from 'utils/helpers';
import { SessionContext } from 'auth/SessionProvider';
import {
  SetActiveModalContext,
  SetModalCountContext,
} from 'components/Clusters/contexts/ClusterContext';
import { ResponseMessage, modalKeys } from 'components/Clusters/helper';
import MenuItemBody from 'components/Clusters/MenuItemBody/MenuItemBody';
import {
  userRolesActions,
  userRolesPages,
  userRolesPagesPermissionsValues,
} from 'types/user';
import { ReactComponent as UpdateIcon } from 'images/update-icon.svg';
import { clusterActionSVGIconStyles } from 'utils/clusters';

function SelectedClustersActions({
  selectedRowKeys,
  clearSelection,
  refetchClusters,
  handleShowErrorModal,
}: {
  selectedRowKeys: any;
  clearSelection: () => void;
  refetchClusters: Function;
  handleShowErrorModal: ({
    message,
    responseMessages,
  }: {
    message: string;
    responseMessages: ResponseMessage;
  }) => void;
}) {
  const { user } = useContext(SessionContext);
  const setActiveModal = useContext(SetActiveModalContext);
  const setModalCount = useContext(SetModalCountContext);

  const userHasEditClusterPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.CLUSTERS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;

  const items: MenuProps['items'] = [
    ...(userRolesActions.CLUSTER_UPGRADE_ACTION in user.userRolesActions &&
    userHasEditClusterPagePermission
      ? [
          {
            key: modalKeys.UPGRADE,
            label: (
              <MenuItemBody
                tooltipTitle="Upgrade"
                btnOnClick={() => {
                  setModalCount('multiple');
                  setActiveModal(modalKeys.UPGRADE);
                }}
              >
                <UpdateIcon
                  style={{ ...clusterActionSVGIconStyles, width: 'unset' }}
                />
                Upgrade
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(userRolesActions.TRANSFER_CLUSTER in user.userRolesActions
      ? [
          {
            key: modalKeys.TRANSFER_OWNERSHIP,
            label: (
              <MenuItemBody
                tooltipTitle="Transfer"
                btnOnClick={() => {
                  setModalCount('multiple');
                  setActiveModal(modalKeys.TRANSFER_OWNERSHIP);
                }}
              >
                <UserSwitchOutlined />
                Transfer
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(userRolesPages.FEATURE_BITS in user.userRolesPagesPermissions
      ? [
          {
            key: modalKeys.FEATURE_BITS,
            label: (
              <MenuItemBody
                tooltipTitle="Feature Bits"
                btnOnClick={() => {
                  setModalCount('multiple');
                  setActiveModal(modalKeys.FEATURE_BITS);
                }}
              >
                <RobotOutlined />
                Feature Bits
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(userRolesActions.BLOCK_CLUSTER_FROM_URL in user.userRolesActions
      ? [
          {
            key: modalKeys.BLOCK_URL,
            label: (
              <MenuItemBody
                tooltipTitle="Block URL"
                btnOnClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to block starting this cluster(s)
                        through accessing the URL?
                      </>
                    ),
                    async onOk() {
                      await multiclusterAction(
                        blockMultiClusterFromUrl,
                        [selectedRowKeys],
                        refetchClusters,
                        handleShowErrorModal,
                      );
                    },
                  });
                }}
              >
                <CheckOutlined />
                Block URL
              </MenuItemBody>
            ),
          },
          {
            key: 'un' + modalKeys.BLOCK_URL,
            label: (
              <MenuItemBody
                tooltipTitle="Unblock URL"
                btnOnClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to unblock starting this
                        cluster(s) through accessing the URL?
                      </>
                    ),
                    async onOk() {
                      await multiclusterAction(
                        unblockMultiClusterFromUrl,
                        [selectedRowKeys],
                        refetchClusters,
                        handleShowErrorModal,
                      );
                    },
                  });
                }}
              >
                <StopOutlined />
                Unblock URL
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(user.userRolesPagesPermissions?.[userRolesPages.CLUSTERS] ===
    userRolesPagesPermissionsValues.CAN_DELETE
      ? [
          {
            key: modalKeys.DELETE,
            label: (
              <MenuItemBody
                tooltipTitle="Delete"
                btnOnClick={() => {
                  Modal.confirm({
                    title: (
                      <>Are you sure you want to delete this cluster(s)?</>
                    ),
                    async onOk() {
                      await multiclusterAction(
                        deleteMultiCluster,
                        [selectedRowKeys],
                        refetchClusters,
                        handleShowErrorModal,
                      );
                    },
                  });
                }}
              >
                <DeleteOutlined />
                Delete
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(userRolesActions.ADD_PREMIUM_PACKAGE_ACTION in user.userRolesActions
      ? [
          {
            key: modalKeys.PREMIUM_PACKAGE,
            label: (
              <MenuItemBody
                tooltipTitle="Add Premium Package"
                btnOnClick={() => {
                  Modal.confirm({
                    title: <>Upgrade to Incorta Premium Package</>,
                    content: (
                      <>
                        Upgrading the cluster(s) to Incorta Premium will enable
                        additional features and capabilities, which have
                        licensing implications. Do you wish to proceed?
                      </>
                    ),
                    async onOk() {
                      await multiclusterAction(
                        upgradeToPremiumPackageBulk,
                        [selectedRowKeys],
                        refetchClusters,
                        handleShowErrorModal,
                      );
                    },
                  });
                }}
              >
                <CrownOutlined />
                Add Premium Package
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(userRolesActions.REMOVE_PREMIUM_PACKAGE_ACTION in user.userRolesActions
      ? [
          {
            key: modalKeys.REMOVE_PREMIUM_PACKAGE,
            label: (
              <MenuItemBody
                tooltipTitle="Remove Premium package"
                btnOnClick={() => {
                  Modal.confirm({
                    title: <>Downgrade to Incorta Standard Package</>,
                    content: (
                      <>
                        By downgrading the cluster(s) to Incorta Standard
                        Package, you will lose access to additional features and
                        capabilities. Do you wish to proceed?
                      </>
                    ),
                    async onOk() {
                      await multiclusterAction(
                        removePremiumPackageBulk,
                        [selectedRowKeys],
                        refetchClusters,
                        handleShowErrorModal,
                      );
                    },
                  });
                }}
              >
                <CrownOutlined
                  style={{
                    transform: 'rotate(-45deg)',
                  }}
                />
                Remove Premium Package
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(userRolesActions.CONNECTION_MNGMENT_CLUSTER in user.userRolesActions &&
    userHasEditClusterPagePermission
      ? [
          {
            key: modalKeys.CONNECTION,
            label: (
              <MenuItemBody
                tooltipTitle="Connect"
                btnOnClick={() => {
                  Modal.confirm({
                    title: (
                      <>Are you sure you want to connect This cluster(s) ?</>
                    ),
                    async onOk() {
                      await multiclusterAction(
                        connectMultiCluster,
                        [selectedRowKeys],
                        refetchClusters,
                        handleShowErrorModal,
                      );
                    },
                  });
                }}
              >
                <CloudOutlined />
                Connect
              </MenuItemBody>
            ),
          },
          {
            key: 'dis' + modalKeys.CONNECTION,
            label: (
              <MenuItemBody
                tooltipTitle="Disconnect"
                btnOnClick={() => {
                  Modal.confirm({
                    title: (
                      <>Are you sure you want to disconnect This cluster(s) ?</>
                    ),
                    async onOk() {
                      await multiclusterAction(
                        disconnectMultiCluster,
                        [selectedRowKeys],
                        refetchClusters,
                        handleShowErrorModal,
                      );
                    },
                  });
                }}
              >
                <DisconnectOutlined />
                Disconnect
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(userRolesActions.LOCK_CLUSTER in user.userRolesActions
      ? [
          {
            key: modalKeys.MASS_LOCK_INTERFACE,
            label: (
              <MenuItemBody
                tooltipTitle="Lock Actions"
                btnOnClick={() => {
                  setModalCount('multiple');
                  setActiveModal(modalKeys.MASS_LOCK_INTERFACE);
                }}
              >
                <LockOutlined />
                Lock Actions
              </MenuItemBody>
            ),
          },
        ]
      : []),
    ...(userRolesActions.CLUSTER_MANAGEMENT_ACTION in user.userRolesActions &&
    userHasEditClusterPagePermission
      ? [
          {
            key: modalKeys.REFRESH,
            label: (
              <MenuItemBody
                tooltipTitle="Refresh Scripts"
                btnOnClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to refresh the cloud scripts for
                        this cluster(s)?
                      </>
                    ),
                    async onOk() {
                      await multiclusterAction(
                        refreshScriptsMultiCluster,
                        [selectedRowKeys],
                        refetchClusters,
                        handleShowErrorModal,
                      );
                    },
                  });
                }}
              >
                <RetweetOutlined />
                Refresh Scripts
              </MenuItemBody>
            ),
          },
          {
            key: modalKeys.COLLECT_METRICS,
            label: (
              <MenuItemBody
                tooltipTitle={'Collect Metrics'}
                btnOnClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to collect the metrics for this
                        cluster(s)?
                      </>
                    ),
                    async onOk() {
                      await multiclusterAction(
                        collectMetricsMultiCluster,
                        [selectedRowKeys],
                        refetchClusters,
                        handleShowErrorModal,
                      );
                    },
                  });
                }}
              >
                <LineChartOutlined />
                Collect Metrics
              </MenuItemBody>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    return () => {
      setModalCount('single');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Space>
      <span className="num-of-selected">
        {selectedRowKeys.length} selected
        <CloseOutlined onClick={() => clearSelection()} />
      </span>
      <Dropdown trigger={['click']} menu={{ items }}>
        <MoreOutlined />
      </Dropdown>
    </Space>
  );
}

export default SelectedClustersActions;
