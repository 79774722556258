import { useCallback } from 'react';
import { PlatformActions } from 'types/user';

function usePlatformActions({
  platformActions,
}: {
  platformActions: PlatformActions[];
}) {
  const getEnabledPlatformsForAction = useCallback(
    (actionName: string) => {
      return platformActions
        ?.find(platformAction => platformAction?.name === actionName)
        ?.platforms?.split(',');
    },
    [platformActions],
  );

  return { getEnabledPlatformsForAction };
}

export default usePlatformActions;
