import { AxiosError } from 'axios';
import { useQuery, useMutation } from 'react-query';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { displayErrorMessage, displaySuccessMessage } from 'utils';
import { getErrorMessage } from 'utils/errors';
import { getProvisionOptions } from 'services/provisionCustomer';
import { createAccount } from 'services/user';
import { NewAccount } from 'types/provisionCustomer.types';

export function useProvisionOptions() {
  return useQuery(
    [QueryServerKeys.PROVISION_CUSTOMER.PROVISION_OPTIONS],
    async () => {
      try {
        const result = await getProvisionOptions();
        return result?.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useCreateAccount() {
  const queryOptions = useMutation({
    mutationKey: QueryServerKeys.PROVISION_CUSTOMER.CREATE_ACCOUNT,
    mutationFn: async ({ account }: { account: NewAccount }) => {
      const res = await createAccount({ ...account });
      return res.data;
    },
    onSuccess(result) {
      // queryClient.invalidateQueries(
      //   QueryServerKeys.CAPACITY_MANAGEMENT.CLUSTER_SIZES_PER_PROVIDER,
      // );
      displaySuccessMessage(result.message);
    },
    onError(error: any) {
      let errors = error?.response?.data?.message;
      if (errors) {
        Object.entries(errors).forEach(([key, v]: any) => {
          if (v.status === 'failure') {
            v.errors.forEach(e => {
              displayErrorMessage(e.value);
            });
          }
        });
      } else {
        displayErrorMessage();
      }
    },
  });
  return queryOptions;
}
