import React from 'react';
import { Button, Space, Modal, Form, Select, message } from 'antd';
import { Option } from 'antd/lib/mentions';
import SizeField from '../SizeField/SizeField';
import TimezoneField from '../TimezoneField/TimezoneField';
import { useSparkImages } from 'hooks/sparkQueries';
import {
  useClusterCustomization,
  useCustomizeCluster,
} from 'hooks/clusterQueries';
import { Instance } from 'types/cluster';

type CustomizeModalProps = {
  instance: Instance;
  close: () => void;
};
function CustomizeModal({ instance, close }: CustomizeModalProps) {
  const { data: sparkImagesData, isLoading: isSparkImagesLoading } =
    useSparkImages({});
  const sparkImages = sparkImagesData?.images;

  const { data: customizationsData, isLoading: isCustomizationsLoading } =
    useClusterCustomization({
      clusterName: instance.name,
    });
  const customizations = customizationsData?.customization;

  const isLoading = isSparkImagesLoading || isCustomizationsLoading;

  const {
    mutateAsync: mutateCustomizeCluster,
    isLoading: isCustomizeClusterLoading,
  } = useCustomizeCluster({ clusterName: instance.name });

  const sparkModes = [
    { name: 'Cluster', value: 'cluster' },
    { name: 'Client', value: 'client' },
  ];

  function imageOptions() {
    return sparkImages?.map((image: any) => (
      <Option key={image.id} value={image.id}>
        {image.name}
      </Option>
    ));
  }

  function findFromByValue(arr, key, value) {
    return arr?.find(e => e[key] === value);
  }

  function initialValues() {
    let sparkCustomImage = findFromByValue(customizations, 'entry', 'spark');
    let sparkCustomMode = findFromByValue(customizations, 'entry', 'sparkMode');
    let sparkMV_Default = findFromByValue(
      customizations,
      'entry',
      'sparkMV_Default',
    );
    let sparkMV_Python = findFromByValue(
      customizations,
      'entry',
      'sparkMV_Python',
    );
    let sparkMV_R = findFromByValue(customizations, 'entry', 'sparkMV_R');
    let sparkMV_Scala = findFromByValue(
      customizations,
      'entry',
      'sparkMV_Scala',
    );

    return {
      spark: parseInt(sparkCustomImage?.value) || undefined,
      sparkMode: sparkCustomMode?.value || undefined,
      timezone: instance.timezone,
      analyticsSizeID: instance.analyticsSizeID,
      loaderSizeID: instance.loaderSizeID,
      sparkMV_Default: parseInt(sparkMV_Default?.value) || undefined,
      sparkMV_Python: parseInt(sparkMV_Python?.value) || undefined,
      sparkMV_R: parseInt(sparkMV_R?.value) || undefined,
      sparkMV_Scala: parseInt(sparkMV_Scala?.value) || undefined,
    };
  }

  async function handleFinish(customizationPayload) {
    try {
      const spark: any = {};
      const content: any = {};
      const mv: any = {};

      if (customizationPayload.spark) {
        spark.id = customizationPayload.spark;
        spark.tag = findFromByValue(
          sparkImages,
          'id',
          customizationPayload.spark,
        ).tag;
      }

      if (customizationPayload.sparkMode) {
        spark.mode = customizationPayload.sparkMode;
      }

      if (customizationPayload.timezone)
        content.timezone = customizationPayload.timezone;
      if (customizationPayload.analyticsSizeID)
        content.analyticsSizeID = customizationPayload.analyticsSizeID;
      if (customizationPayload.loaderSizeID)
        content.loaderSizeID = customizationPayload.loaderSizeID;
      if (customizationPayload.sparkMV_Default)
        mv['sparkMV_Default'] = customizationPayload.sparkMV_Default;
      if (customizationPayload.sparkMV_Python)
        mv['sparkMV_Python'] = customizationPayload.sparkMV_Python;
      if (customizationPayload.sparkMV_R)
        mv['sparkMV_R'] = customizationPayload.sparkMV_R;
      if (customizationPayload.sparkMV_Scala)
        mv['sparkMV_Scala'] = customizationPayload.sparkMV_Scala;

      const payload = { spark, mv, ...content };
      await mutateCustomizeCluster({
        payload,
      });
      payload;
    } catch (e: any) {
      message.error(e.message);
    }
    close();
  }

  return (
    <Modal
      title="Instance Customizations"
      visible={true}
      footer={null}
      width={'50%'}
      onCancel={close}
    >
      {!isLoading && (
        <Form
          initialValues={initialValues()}
          className="FeatureBits"
          layout="vertical"
          name="Customize Instance"
          onFinish={handleFinish}
        >
          <Form.Item name="spark" label="Spark Image">
            <Select dropdownClassName="SizeField__select-dropdown">
              {imageOptions()}
            </Select>
          </Form.Item>
          <Form.Item name="sparkMode" label="Spark Mode">
            <Select dropdownClassName="SizeField__select-dropdown">
              {sparkModes.map(mode => (
                <Option key={mode.value} value={mode.value}>
                  {mode.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <SizeField
            label="Analytics Size"
            name="analyticsSizeID"
            instanceID={instance.id}
            pod={'analytics'}
          />
          <SizeField
            label="Loader Size"
            name="loaderSizeID"
            instanceID={instance.id}
            pod={'loader'}
          />
          <TimezoneField label="Timezone" name="timezone" />
          <Form.Item name="sparkMV_Default" label="MV Default Spark Image">
            <Select dropdownClassName="SizeField__select-dropdown">
              {imageOptions()}
            </Select>
          </Form.Item>
          <Form.Item name="sparkMV_Python" label="MV Python Spark Image">
            <Select dropdownClassName="SizeField__select-dropdown">
              {imageOptions()}
            </Select>
          </Form.Item>
          <Form.Item name="sparkMV_R" label="MV Spark R Image">
            <Select dropdownClassName="SizeField__select-dropdown">
              {imageOptions()}
            </Select>
          </Form.Item>
          <Form.Item name="sparkMV_Scala" label="MV Spark Scala Image">
            <Select dropdownClassName="SizeField__select-dropdown">
              {imageOptions()}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={isCustomizeClusterLoading}
              >
                Update
              </Button>
              <Button onClick={close}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
export default CustomizeModal;
