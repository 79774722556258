import React, { useState } from 'react';
import './NotebookModal.less';
import {
  Button,
  Form,
  Switch,
  Slider,
  Tooltip,
  Card,
  notification,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AnalyticsLoaderSize } from 'types/cluster';
import { useGetNotebookUsersConfigs, useUpdateNotebook } from 'hooks/queries';

const defaultNotebookUsersCountForCustomSize = 50 as const;

type NotebookModal = {
  instanceID: string;
  isClusterRunning: boolean;
  clusterSize: AnalyticsLoaderSize;
  isChidori: boolean;
  enableBusinessUserNotebook: boolean;
  originalNotebookUsersCount: number;
  platform: string;
  close: Function;
};
function NotebookModal({
  instanceID,
  isClusterRunning,
  clusterSize,
  isChidori,
  enableBusinessUserNotebook,
  originalNotebookUsersCount,
  platform,
  close,
}: NotebookModal) {
  const {
    data: notebookUsersConfigsData,
    isLoading: isNotebookUsersConfigsDataLoading,
    isError: hasNotebookUsersConfigsDataError,
  } = useGetNotebookUsersConfigs({ enabled: isChidori });
  const xLargeClusterSizeValue = notebookUsersConfigsData?.find?.(
    config => config.size === 'xlarge',
  )?.sizeValue;
  const largestClusterSize =
    xLargeClusterSizeValue || defaultNotebookUsersCountForCustomSize;

  const {
    mutateAsync: mutateUpdateNotebook,
    isLoading: isUpdateNotebookLoading,
    isError: hasUpdateNotebookError,
  } = useUpdateNotebook({
    instanceID,
  });

  const maxNotebookUsersCount = clusterSize.isCustomSize
    ? largestClusterSize
    : notebookUsersConfigsData?.find(config => {
        return config.size?.toLocaleLowerCase() === clusterSize.name;
      })?.sizeValue;
  const [notebookCount, setNotebookCount] = useState(
    originalNotebookUsersCount,
  );
  const value = notebookCount ?? maxNotebookUsersCount ?? 50;

  function handleSliderChange(value) {
    setNotebookCount(value);
  }

  const [form] = Form.useForm();

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  async function handleSubmit(values: {
    enableBusinessUserNotebook: boolean;
    notebookCount: number;
  }) {
    const alreadyEnabled = enableBusinessUserNotebook;
    const useDefaultMaxNotebookCount =
      !alreadyEnabled ||
      (values.enableBusinessUserNotebook === true &&
        !originalNotebookUsersCount);

    const res = await mutateUpdateNotebook({
      enableBusinessUserNotebook: values.enableBusinessUserNotebook,
      businessUserNotebookCount: useDefaultMaxNotebookCount
        ? maxNotebookUsersCount
        : values.notebookCount,
    });

    if (res.status.toString().startsWith('2')) {
      notification.success({ message: res.message });
      close();
    }
  }

  async function onValuesChange(changedValues: any, allValues: any) {
    setTimeout(() => {
      const fieldsValues = form.getFieldsValue();
      const shouldDisable = Object.values(fieldsValues).some(
        value => !value && value !== false,
      );

      setIsSubmitButtonDisabled(shouldDisable);
    }, 500);
  }

  function handleToggleNotebook(isToggled: boolean) {
    setIsSubmitButtonDisabled(enableBusinessUserNotebook === isToggled);
    form.setFieldValue('enableBusinessUserNotebook', isToggled);
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{ enableBusinessUserNotebook, notebookCount: value }}
      onValuesChange={onValuesChange}
      className="notebook-modal__form"
    >
      <p>Cluster Size: {clusterSize.displayName}</p>
      <section className="notebook-modal__enable-notebook-item-wrapper">
        <Tooltip
          title={
            platform !== 'gc'
              ? 'Business Notebook is available for GCP Clusters Only'
              : ''
          }
        >
          <Form.Item
            name="enableBusinessUserNotebook"
            label={<label>{'Business User Notebook '}</label>}
            valuePropName="checked"
            className="notebook-modal__enable-notebook-item"
          >
            {/* <section> */}
            <Switch
              disabled={
                !isClusterRunning ||
                platform !== 'gc' ||
                isUpdateNotebookLoading
              }
              onChange={handleToggleNotebook}
            />
            {/* <article>
              <InfoCircleOutlined /> This will cause analytics & loader services
              to restart
            </article>
          </section> */}
          </Form.Item>
          <article>
            <InfoCircleOutlined /> This will cause analytics & loader services
            to restart
          </article>
        </Tooltip>
      </section>
      {enableBusinessUserNotebook ? (
        <Card>
          <p>Users Count</p>
          <p>{value} Users</p>
          <Form.Item name="notebookCount">
            <Slider
              value={value}
              min={1}
              max={maxNotebookUsersCount}
              onChange={value => handleSliderChange(value)}
              disabled={
                isNotebookUsersConfigsDataLoading ||
                hasNotebookUsersConfigsDataError
              }
            />
          </Form.Item>
        </Card>
      ) : null}
      <br />
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          disabled={isSubmitButtonDisabled}
          loading={isUpdateNotebookLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

export default NotebookModal;
