import { InstancePlatform } from 'types/cluster';

export const clusterSizesMap = new Map([
  ['small', 'XSmall'],
  ['medium', 'Small'],
  ['larges1', 'Medium'],
  ['larges2', 'Large'],
  ['larges3', 'XLarge'],
  ['larges4', '2XLarge'],
  ['larges5', '4XLarge'],
  ['larges6', '6XLarge'],
  ['larges7', '10XLarge'],
]);

export function actionDoesNotSupportAzureTooltip({
  action,
  platform,
}: {
  action: string;
  platform: InstancePlatform;
}) {
  let isActionDisabled = false;
  let tooltip: string | null = null;
  if (platform !== InstancePlatform.GC) {
    isActionDisabled = true;
    tooltip = `${action} is not supported in Azure clusters yet!`;
  }
  return {
    isActionDisabled,
    tooltip,
  };
}

export function notebookTooltipSpecificCondition({
  isBusinessNotebookVersionSupported,
  minimumVersion,
  platform,
}: {
  isBusinessNotebookVersionSupported: boolean;
  minimumVersion: string;
  platform: InstancePlatform;
}) {
  let isActionDisabled = false;
  let tooltip: string | null = null;
  if (!isBusinessNotebookVersionSupported) {
    isActionDisabled = true;
    tooltip = `Version is not supported, Upgrade to ${minimumVersion} or newer`;
  }
  if (platform !== InstancePlatform.GC) {
    isActionDisabled = true;
    tooltip = 'Notebook is not supported in Azure clusters yet!';
  }
  return {
    isActionDisabled,
    tooltip,
  };
}

export function platformActionsSpecificCondition({
  action,
  enabledPlatforms,
  platform,
}: {
  action: string;
  enabledPlatforms: InstancePlatform[];
  platform: InstancePlatform;
}) {
  let isActionDisabled = false;
  let tooltip: string | null = null;

  if (!enabledPlatforms.includes(platform)) {
    isActionDisabled = true;
    const platformName =
      platform === InstancePlatform.GC
        ? 'Google Cloud'
        : platform === InstancePlatform.AZURE
        ? 'Azure'
        : 'Azure-SaaS';
    tooltip = `${action} is not enabled as a "Platform Action" for ${platformName} clusters`;
  }

  return {
    isActionDisabled,
    tooltip,
  };
}

type checkIsClusterActionEnabledAndGetTooltipProps = {
  action: string;
  status: string;
  checkRunning?: boolean;
  loaderStatus?: string;
  evaluatePrioritzedCondtion?: () => {
    isActionDisabled: boolean;
    tooltip: string;
  };
};

export function checkIsClusterActionEnabledAndGetTooltip({
  action,
  status,
  checkRunning = false,
  loaderStatus,
  evaluatePrioritzedCondtion,
}: checkIsClusterActionEnabledAndGetTooltipProps) {
  let isBtnDisabled: boolean = false;
  let tooltipTitle: string | null = action;

  let isPrioritizedConditionApplied = false;
  if (evaluatePrioritzedCondtion) {
    let { isActionDisabled, tooltip } = evaluatePrioritzedCondtion();
    if (tooltip !== null) {
      isPrioritizedConditionApplied = true;
      tooltipTitle = tooltip;
      isBtnDisabled = isActionDisabled;
    }
  }

  if (checkRunning && !isPrioritizedConditionApplied) {
    if (status !== 'running') {
      isBtnDisabled = true;
      tooltipTitle = 'Cluster Must be running.';
    } else if (loaderStatus !== undefined && loaderStatus !== 'running') {
      isBtnDisabled = true;
      tooltipTitle = 'Loader Service must be running.';
    } else {
      isBtnDisabled = false;
      tooltipTitle = action;
    }
  }

  return { isBtnDisabled, tooltipTitle };
}

export const clusterActionSVGIconStyles: React.CSSProperties = {
  width: '12px',
  height: 'auto',
  marginInlineEnd: '8px',
};

export const moreInfoSVGIconStyles: React.CSSProperties = {
  width: '14px',
  height: '14px',
};
