import axios from 'axios';
import { SparkImagesResult } from 'types/spark';
import { API_URL } from 'utils/http';

export function getSparkImages({
  page = 1,
  search,
}: {
  page?: number;
  search?: string;
}): any {
  return axios.get<SparkImagesResult>(`${API_URL}/spark`, {
    params: { page, search },
  });
}

export function createSparkImage(image) {
  return axios.post(`${API_URL}/spark`, image);
}

export function updateSparkImage(id, image) {
  return axios.put(`${API_URL}/spark/${id}`, image);
}

export function getSparkTags() {
  return axios.get(`${API_URL}/spark/tags`);
}
