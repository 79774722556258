import React, { useState } from 'react';
import './ClusterForm.less';
import { Form, FormInstance, Input, Select } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { InstancePlatform, Region } from 'types/cluster';
import {
  ProvisionOptions,
  ProvisionUserRole,
} from 'types/provisionCustomer.types';
import {
  useClusterSizesPerClusterCode,
  useGetCloudProviders,
} from 'hooks/capacityManagementQueries';
import useCloudProvider from 'hooks/useCloudProvider';

const { Option, OptGroup } = Select;

type ClusterFormProps = {
  index: number;
  provisionOptionsIsPending: boolean;
  provisionOptions: ProvisionOptions;
  form: FormInstance<any>;
  clusters: {
    name: string;
    userRole: ProvisionUserRole;
    analyticsSizeID: number;
    region: string;
    cluster_code: string;
    environmentType: string;
    autoSuspend: string;
  }[];
};
function ClusterForm({
  index,
  provisionOptionsIsPending,
  provisionOptions,
  form,
  clusters,
}: ClusterFormProps) {
  const { data: cloudProviders } = useGetCloudProviders();

  const { CloudProviderLogoMap } = useCloudProvider();

  const [selectedClusterCode, setSelectedClusterCode] = useState();

  const {
    data: clusterSizesPerClusterCode,
    isLoading: isClusterSizesPerClusterCodeLoading,
  } = useClusterSizesPerClusterCode({
    clusterCode: selectedClusterCode,
  });

  return (
    <>
      <Form.Item
        name={['clusters', index, 'name']}
        label="Cluster Name"
        rules={[
          {
            required: true,
            message: 'Cluster Name is required',
          },
          {
            message: 'Cluster Name already used',
            validator: (_, value) => {
              if (
                form
                  .getFieldsValue()
                  ?.clusters?.filter(
                    cluster => value !== '' && cluster.name === value,
                  ).length > 1
              ) {
                return Promise.reject('Cluster Name already used');
              } else {
                return Promise.resolve(value);
              }
            },
          },
        ]}
      >
        <Input
          placeholder="IC-AccountName-Environment"
          onChange={e => (clusters[index].name = e.target.value)}
        />
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'userRole']}
        label="User Role"
        rules={[{ required: true, message: 'User Role is required' }]}
      >
        <Select disabled loading={provisionOptionsIsPending}>
          {provisionOptions?.userRoles?.map(role => {
            return (
              <Select.Option key={role} value={role}>
                {role}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'cluster_code']}
        label="Cluster Region"
        rules={[{ required: true, message: 'Cluster Region is required' }]}
      >
        <Select
          placeholder="Select"
          loading={
            provisionOptionsIsPending || isClusterSizesPerClusterCodeLoading
          }
          onChange={value => {
            // clusters[index].region = value;
            setSelectedClusterCode(value);
          }}
          className="cluster-form__region-select-wrapper"
        >
          {cloudProviders?.map(cloudProvider => (
            <OptGroup
              key={cloudProvider.key}
              label={
                <div className="cluster-form__region-select-group-wrapper">
                  {CloudProviderLogoMap.get(
                    cloudProvider.key as InstancePlatform,
                  ) || <Icon />}
                  <label>{cloudProvider.displayName}</label>
                </div>
              }
            >
              {provisionOptions?.clusterRegions
                .filter(region => cloudProvider.key === region.platform)
                ?.map((region: Region) => (
                  <Option
                    disabled={region.platform !== InstancePlatform.GC}
                    key={`${region.region}--${region.cluster_code}`}
                    className="cluster-form__region-select-item"
                    value={region.cluster_code}
                  >
                    <label>{region.regionAlias}</label>
                    <label>{region.cluster_code}</label>
                  </Option>
                ))}
            </OptGroup>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'analyticsSizeID']}
        label="Cluster Size"
        rules={[
          { required: true, message: 'Cluster Size is required' },
          {
            message: 'Cluster Size is required',
            validator: (_, value) => {
              if (value === 'Select') {
                return Promise.reject('Cluster Size is required');
              } else {
                return Promise.resolve(value);
              }
            },
          },
        ]}
      >
        <Select
          loading={provisionOptionsIsPending}
          onChange={value => {
            clusters[index].analyticsSizeID = value;
          }}
          disabled={!selectedClusterCode}
          placeholder={
            !selectedClusterCode ? 'Select Region firstly' : 'Select'
          }
        >
          {clusterSizesPerClusterCode?.map(size => {
            return (
              <Select.Option key={size.name} value={size.id}>
                {size.displayName}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'environmentType']}
        label="Environment Type"
        rules={[
          { required: true, message: 'Environment Type is required' },
          {
            message: 'Environment Type is required',
            validator: (_, value) => {
              if (value === 'Select') {
                return Promise.reject('Environment Type is required');
              } else {
                return Promise.resolve(value);
              }
            },
          },
        ]}
      >
        <Select
          loading={provisionOptionsIsPending}
          onChange={value => {
            let type = form.getFieldValue([
              'clusters',
              index,
              'environmentType',
            ]);
            form.setFieldsValue({
              clusters: form.getFieldsValue()?.clusters?.map((_, i) => ({
                userRole: form.getFieldValue(['clusters', i, 'userRole']),
                analyticsSizeID: form.getFieldValue([
                  'clusters',
                  i,
                  'analyticsSizeID',
                ]),
                region: form.getFieldValue(['clusters', i, 'region']),
                cluster_code: form.getFieldValue([
                  'clusters',
                  i,
                  'cluster_code',
                ]),
                name: form.getFieldValue(['clusters', i, 'name']),
                slaTypeID: 1,
                costTypeID: 1,
                environmentType: form.getFieldValue([
                  'clusters',
                  i,
                  'environmentType',
                ]),
                autoSuspend:
                  index === i
                    ? type === 'Prod'
                      ? 'Never'
                      : '1 Hour'
                    : form.getFieldValue(['clusters', i, 'autoSuspend']),
              })),
            });

            clusters[index].environmentType = value;
            if (type !== 'Prod') {
              clusters[index].autoSuspend = '1 Hour';
            }
          }}
        >
          {provisionOptions?.environmentTypes?.map(type => {
            return (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'autoSuspend']}
        label="Auto Suspend"
        rules={[{ required: true, message: 'Auto Suspend is required' }]}
      >
        <Select
          loading={provisionOptionsIsPending}
          onChange={value => (clusters[index].autoSuspend = value)}
        >
          {provisionOptions?.availableIdleTime?.map(time => {
            return (
              <Select.Option key={time.key} value={time.display}>
                {time.display}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
}

export default ClusterForm;
