import React from 'react';
import { Empty, Spin } from 'antd';
import { useGetOperatorsPerPlatform } from 'hooks/capacityManagementQueries';
import { InstancePlatform } from 'types/cluster';
import { CloudProviderItem } from 'types/capacityManagement.types';
import CloudCapacityManagementTable from 'components/guidance/CloudCapacityManagement/CloudCapacityManagementTable/CloudCapacityManagementTable';

function CloudProviderTab({
  cloudProvider,
  showModalForNewSize,
  setShowModalForNewSize,
}: // activeTabKey,
{
  cloudProvider: CloudProviderItem;
  showModalForNewSize: boolean;
  setShowModalForNewSize: React.Dispatch<React.SetStateAction<boolean>>;
  // activeTabKey?: string;
}) {
  const {
    data: operatorsPerPlatformResult,
    isLoading: isOperatorsPerPlatformLoading,
    isError: hasOperatorsPerPlatformError,
  } = useGetOperatorsPerPlatform({
    platform:
      // (activeTabKey as InstancePlatform) ||
      cloudProvider.key as InstancePlatform,
  });

  const operatorsPerPlatform =
    operatorsPerPlatformResult?.data?.platformOperators;

  if (isOperatorsPerPlatformLoading) {
    return <Spin />;
  } else if (hasOperatorsPerPlatformError) {
    return <>Something went wrong</>;
  } else {
    const url = new URL(operatorsPerPlatformResult?.config?.url);
    const queryParams = new URLSearchParams(url.search);
    const platformKeyFromRequestURL = queryParams.get('platform');

    if (cloudProvider.key === platformKeyFromRequestURL) {
      if (!operatorsPerPlatform?.length) {
        return <Empty />;
      }
      return (
        <CloudCapacityManagementTable
          key={cloudProvider.id}
          cloudProviderID={cloudProvider.id}
          cloudProviderKey={cloudProvider.key}
          operatorsPerPlatform={operatorsPerPlatform}
          showModalForNewSize={showModalForNewSize}
          setShowModalForNewSize={setShowModalForNewSize}
        />
      );
    } else {
      return <>fetching tab data</>;
    }
  }
}

export default CloudProviderTab;
