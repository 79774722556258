import React from 'react';
import { Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useGetAffectedInstancesFromModifiedClusterSize } from 'hooks/capacityManagementQueries';
import { AffectedInstancesFromModifiedSizeItem } from 'types/capacityManagement.types';

type Props = {
  clusterSizeID?: number;
};
function ClusterSizeAffectedInstancesStep({ clusterSizeID }: Props) {
  const {
    data: affectedInstances,
    isLoading: isAffectedInstancesFromModifiedClusterSizeLoading,
  } = useGetAffectedInstancesFromModifiedClusterSize({ sizeID: clusterSizeID });
  const columns: ColumnsType<AffectedInstancesFromModifiedSizeItem> = [
    {
      title: 'Cluster Name',
      dataIndex: 'name',
    },
  ];

  return (
    <Row>
      <Col
        span={6}
        style={{
          textAlign: 'end',
          paddingInlineEnd: '8px',
          paddingBlockStart: '16px',
        }}
      >
        Affected Clusters
      </Col>

      <Col span={18}>
        <Table
          pagination={false}
          bordered
          columns={columns}
          dataSource={affectedInstances}
          loading={isAffectedInstancesFromModifiedClusterSizeLoading}
        />
      </Col>
    </Row>
  );
}

export default ClusterSizeAffectedInstancesStep;
