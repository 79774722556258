import { PropsWithChildren } from 'react';
import './MoreInfoTag.less';
import { Tag, TagProps } from 'antd';

function MoreInfoTag(props: PropsWithChildren<TagProps>) {
  return (
    <Tag className="cluster-more-info-tag" {...props}>
      {props.children}
    </Tag>
  );
}

export default MoreInfoTag;
