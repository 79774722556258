import React, { useEffect, useState } from 'react';
import './ClusterSizeOperatorsStep.less';
import { Form, FormInstance, InputNumber, Select, Table, Tooltip } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ColumnsType } from 'antd/lib/table';
import { InfoCircleOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { fieldNames } from '../../ClusterSizeCellModal/ClusterSizeCellModal';
import {
  useGetCloudProviders,
  useGetOperatorsPerPlatform,
} from 'hooks/capacityManagementQueries';
import { InstancePlatform } from 'types/cluster';
import { OperatorPerPlatformItem } from 'types/capacityManagement.types';
import useCloudProvider from 'hooks/useCloudProvider';
import NodeLabelsRenderer from 'components/guidance/CloudCapacityManagement/Steps/ClusterSizeOperatorsStep/NodeLabelsRenderer/NodeLabelsRenderer';

const maxMemoryValue = 10000;
const maxCPUValue = 1000;

function ClusterSizeOperatorsStep({
  isCreateNewSize,
  form,
  initialValues,
  cloudProviderID,
  cloudProviderKey,
  clusterCode,
  clusterCodeID,
  operatorsDataSource,
  setOperatorsDataSource,
  selectedOperatorsIDs,
  setSelectedOperatorsIDs,
}: {
  isCreateNewSize: boolean;
  form?: FormInstance<any>; // TODO: FIXME:
  initialValues?: any;
  cloudProviderID?: number;
  cloudProviderKey?: string;
  clusterCode?: string;
  clusterCodeID?: number;
  operatorsDataSource: any[];
  setOperatorsDataSource: React.Dispatch<React.SetStateAction<any[]>>;
  selectedOperatorsIDs: number[];
  setSelectedOperatorsIDs: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const { CloudProviderLogoMap } = useCloudProvider();

  if (!Object.keys(initialValues).length && cloudProviderID) {
    initialValues.regionProvider = {
      cloudProviderID,
    };
  }

  const { data: cloudProviders } = useGetCloudProviders();
  const [currentCloudProviderKey, setCurrentCloudProviderKey] = useState(
    cloudProviderKey ||
      cloudProviders?.find(
        cloudProvider => cloudProvider.id === cloudProviderID,
      ).key,
  );

  const {
    data: operatorsPerPlatformResult,
    isLoading: isOperatorsPerPlatformLoading,
    isError: hasOperatorsPerPlatformError,
  } = useGetOperatorsPerPlatform({
    platform: currentCloudProviderKey as InstancePlatform,
    ...(clusterCode
      ? {
          operatorNameFilter: clusterCode,
        }
      : {}),
  });
  const operatorsPerPlatform =
    operatorsPerPlatformResult?.data?.platformOperators;

  useEffect(() => {
    if (!!operatorsPerPlatform && Array.isArray(operatorsPerPlatform)) {
      const hasNodeLabelObj =
        initialValues.nodeLabelObj &&
        Object.keys(initialValues.nodeLabelObj).length !== 0;

      const selectedOperatorsValue = operatorsPerPlatform
        .filter(op =>
          currentCloudProviderKey !== InstancePlatform.GC || !isCreateNewSize
            ? true
            : true,
        )
        ?.map(op => ({
          ...op,
          checked: isCreateNewSize ? false : true,
          ...(!isCreateNewSize && initialValues
            ? {
                label: hasNodeLabelObj
                  ? initialValues.nodeLabelObj
                  : initialValues.nodeLabel,
                cpu: initialValues.cpu,
                memoryRequest: initialValues.memoryRequest,
                memoryLimit: initialValues.memoryLimit,
              }
            : {}),
        }));
      form.setFieldValue('selectedOperators', selectedOperatorsValue);
    }
  }, [operatorsPerPlatform]);

  useEffect(() => {
    if (operatorsPerPlatform?.length) {
      const currentNodeLabel = operatorsPerPlatform?.[0]?.nodepools?.find(
        nodePool => nodePool?.label === initialValues?.nodeLabel,
      );

      const hasNodeLabelObj =
        initialValues.nodeLabelObj &&
        Object.keys(initialValues.nodeLabelObj).length !== 0;

      setOperatorsDataSource(
        operatorsPerPlatform?.map(op => ({
          ...op,
          code: op.clusterCode,
          label: hasNodeLabelObj
            ? initialValues.nodeLabelObj
            : initialValues.nodeLabel,
          cpu: initialValues.cpu,
          ...(!isCreateNewSize && initialValues && currentNodeLabel
            ? {
                vCPUs: currentNodeLabel?.vCPUs,
              }
            : {}),
          memoryLimit: !isCreateNewSize ? initialValues.memoryLimit : 1,
          memoryText: !isCreateNewSize ? initialValues.memoryText : 1,
          ...(!isCreateNewSize && initialValues.memoryRequest
            ? { memoryRequest: initialValues.memoryRequest }
            : {}),
        })),
      );
    }
  }, [currentCloudProviderKey, operatorsPerPlatform]);

  const columns: ColumnsType<
    OperatorPerPlatformItem & {
      memoryRequest: number;
      memoryText: number;
      memoryLimit: number;
      label: string;
      cpu: number;
      code: string;
    }
  > = [
    {
      title: 'Operator',
      dataIndex: 'clusterCode',
    },
    {
      title: 'Node Labels',
      dataIndex: 'nodeLabel',
      width: 310,
      ellipsis: true,
      render(rowValue, { nodepools, clusterCode, region, id }, index) {
        if (true) {
          return (
            <NodeLabelsRenderer
              currentNodeLabelRow={
                operatorsDataSource.find(row => row.id === id)!
              }
              onNodeLabelsChangeForm={async ({ selectedNodeLabels }) => {
                const val = selectedNodeLabels;
                const currentID = operatorsDataSource[index].id;
                const selectedOperatorsValue = await form.getFieldValue(
                  'selectedOperators',
                );
                const updatedSelectedOperatorsValueAfterChangingLabel =
                  selectedOperatorsValue.map(op => {
                    if (op.id === currentID) {
                      return { ...op, label: val };
                    }
                    return op;
                  });
                form.setFieldValue(
                  'selectedOperators',
                  updatedSelectedOperatorsValueAfterChangingLabel,
                );
                form.validateFields();
                const newDataSource = operatorsDataSource.map(item => {
                  if (item.id === currentID) {
                    return {
                      ...item,
                      label: selectedNodeLabels,
                    };
                  }
                  return item;
                });
                setOperatorsDataSource(newDataSource);
              }}
            />
          );
        }
      },
    },
    {
      title: (
        <>
          {'vCPU '}
          <Tooltip title="Allocated CPU for Each Service">
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'cpu',
      align: 'center',
      render(value, { label }, index) {
        const max = maxCPUValue;
        return (
          <InputNumber
            defaultValue={value}
            value={value}
            min={1}
            max={max}
            type="number"
            onChange={async val => {
              const currentID = operatorsDataSource[index].id;
              const selectedOperatorsValue = await form.getFieldValue(
                'selectedOperators',
              );
              const updatedOperatorsValueAfterChangingCPU =
                selectedOperatorsValue.map(op => {
                  if (op.id === currentID) {
                    if (val >= 1 && val <= max) {
                      return {
                        ...op,
                        cpu: val,
                      };
                    } else {
                      return op;
                    }
                  }
                  return op;
                });
              form.setFieldValue(
                'selectedOperators',
                updatedOperatorsValueAfterChangingCPU,
              );
              form.validateFields();

              const newDataSource = operatorsDataSource.map(item => {
                if (item.id === currentID) {
                  return {
                    ...item,
                    cpu: val,
                  };
                }
                return item;
              });
              setOperatorsDataSource(newDataSource);
            }}
          />
        );
      },
    },
    {
      title: (
        <>
          {'Limit '}
          <Tooltip title="Maximum Allocated Memory for Each Service">
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'memoryLimit',
      align: 'center',
      render(value, { nodepools }, index) {
        if (true) {
          return (
            <InputNumber
              defaultValue={initialValues?.memoryLimit}
              min={1}
              max={maxMemoryValue}
              type="number"
              onChange={async val => {
                const currentID = operatorsDataSource[index].id;
                const selectedOperatorsValue = await form.getFieldValue(
                  'selectedOperators',
                );
                const updatedSelectedOperatorsValueAfterChangingMemoryLimit =
                  selectedOperatorsValue.map(op => {
                    if (op.id === currentID) {
                      if (val >= 1 && val <= maxMemoryValue) {
                        return {
                          ...op,
                          ...(val < op.memoryRequest
                            ? {
                                memoryRequest: val,
                              }
                            : {}),
                          memoryLimit: val,
                          ...(currentCloudProviderKey !== InstancePlatform.GC
                            ? {
                                memoryText: +val + 2,
                              }
                            : {}),
                        };
                      } else {
                        return op;
                      }
                    }
                    return op;
                  });
                form.setFieldValue(
                  'selectedOperators',
                  updatedSelectedOperatorsValueAfterChangingMemoryLimit,
                );
                form.validateFields();

                const newDataSource = operatorsDataSource.map(item => {
                  if (item.id === currentID) {
                    return {
                      ...item,
                      memoryLimit: val,
                      memoryText: val,
                      ...(val < item.memoryRequest
                        ? {
                            memoryRequest: val,
                          }
                        : {}),
                    };
                  }
                  return item;
                });
                setOperatorsDataSource(newDataSource);
              }}
            />
          );
        }
      },
    },
    {
      title: (
        <>
          {'Request '}
          <Tooltip title="Minimum Allocated Memory for Each Service">
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'memoryRequest',
      align: 'center',
      render(value, { memoryLimit, nodepools }, index) {
        return (
          <InputNumber
            defaultValue={value}
            value={value}
            min={1}
            max={memoryLimit}
            type="number"
            onChange={async val => {
              const currentID = operatorsDataSource[index].id;
              const selectedOperatorsValue = await form.getFieldValue(
                'selectedOperators',
              );
              const updatedOperatorsValueAfterChangingMemoryRequest =
                selectedOperatorsValue.map(op => {
                  if (op.id === currentID) {
                    if (val >= 1 && val <= maxMemoryValue) {
                      return {
                        ...op,
                        memoryRequest: val,
                      };
                    } else {
                      return op;
                    }
                  }
                  return op;
                });
              form.setFieldValue(
                'selectedOperators',
                updatedOperatorsValueAfterChangingMemoryRequest,
              );
              form.validateFields();

              const newDataSource = operatorsDataSource.map(item => {
                if (item.id === currentID) {
                  return {
                    ...item,
                    memoryRequest: val,
                  };
                }
                return item;
              });
              setOperatorsDataSource(newDataSource);
            }}
          />
        );
      },
    },
    ,
  ];

  const onOperatorsSelectionChange = async (
    selectedRowIds: number[],
    selectedRows: OperatorPerPlatformItem[],
  ) => {
    const selectedOperatorsValue = await form.getFieldValue(
      'selectedOperators',
    );
    const updatedOperatorsValueAfterSelectionChange =
      selectedOperatorsValue.map(op => {
        const isChecked = selectedRowIds.includes(op.id);
        return { ...op, checked: isChecked };
      });
    form.setFieldValue(
      'selectedOperators',
      updatedOperatorsValueAfterSelectionChange,
    );
    form.validateFields();
    setSelectedOperatorsIDs(selectedRowIds);
  };

  return (
    <Form
      name="ClusterSizeOperatorsStep"
      initialValues={initialValues}
      form={form}
    >
      <FormItem
        name={fieldNames.platform}
        label="Platform"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        required
      >
        <Select
          disabled={!isCreateNewSize}
          options={cloudProviders
            ?.filter(
              cloudProvider =>
                ![InstancePlatform.AWS, InstancePlatform.AZURE].includes(
                  cloudProvider.key as InstancePlatform,
                ),
            )
            ?.map(cloudProvider => ({
              label: (
                <article
                  key={cloudProvider.id}
                  className={`operators-step__platform-select-label${
                    !isCreateNewSize ? ' disabled' : ''
                  }`}
                >
                  {CloudProviderLogoMap.get(
                    cloudProvider.key as InstancePlatform,
                  ) || <Icon />}
                  {cloudProvider.displayName}
                </article>
              ),
              value: cloudProvider.id,
              cloudPrvdrKey: cloudProvider.key,
            }))}
          showSearch
          filterOption={(input, option) =>
            option?.label
              ?.toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(
            value: number,
            option: {
              label: JSX.Element;
              value: number;
              cloudPrvdrKey: string;
            },
          ) => {
            setCurrentCloudProviderKey(option.cloudPrvdrKey);
            setSelectedOperatorsIDs([]);
          }}
        />
      </FormItem>
      <FormItem
        name="selectedOperators"
        label="Operators"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        className="operators-step__operators-form-item"
        required
        rules={[
          {
            validator: async () => {
              const currentOperators = await form.getFieldValue(
                'selectedOperators',
              );
              const isAnyOperatorSelected =
                Array.isArray(selectedOperatorsIDs) &&
                selectedOperatorsIDs.length > 0;
              const selectedOperators = currentOperators.filter(operator =>
                selectedOperatorsIDs.includes(operator.id),
              );

              const isAllSelectedOperatorsHasFilledNodeLabels =
                !!currentOperators?.length &&
                selectedOperators.every(
                  operator =>
                    operator.label &&
                    'analytics' in operator.label &&
                    operator.label['analytics'] &&
                    'loader' in operator.label &&
                    operator.label['loader'],
                );

              if (!isAllSelectedOperatorsHasFilledNodeLabels) {
                return Promise.reject(
                  'Make sure each selected operator has Node labels keys and values',
                );
              }

              const isAllSelectedOperatorsFilled =
                !!currentOperators?.length &&
                selectedOperators.every(
                  operator =>
                    operator.label &&
                    operator.memoryRequest &&
                    operator.memoryLimit &&
                    operator.cpu,
                );

              if (!isAnyOperatorSelected) {
                return Promise.reject('Select an Operator');
              }
              if (!isAllSelectedOperatorsFilled) {
                return Promise.reject('Fill the fields');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Table
          size="small"
          columns={columns}
          dataSource={operatorsDataSource}
          rowKey="id"
          loading={isOperatorsPerPlatformLoading}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedOperatorsIDs,
            preserveSelectedRowKeys: true,
            onChange: onOperatorsSelectionChange,
            getCheckboxProps: (record: {
              id: number;
              region: string;
              clusterCode: string;
              nodepools: {
                label: string;
                memoryLimit: number;
                memoryText: number;
              }[];
              code: string;
              memoryLimit: number;
              memoryText: number;
              memoryRequest: number;
            }) => {
              return {
                disabled: !isCreateNewSize ? true : false,
              };
            },
          }}
          pagination={false}
        />
      </FormItem>
    </Form>
  );
}

export default ClusterSizeOperatorsStep;
