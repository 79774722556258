import './NewProvisionCustomer.less';

import { Button, Collapse, Form } from 'antd';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import CollapseHeader from 'components/NewProvisionCustomer/components/CollapseHeader/CollapseHeader';
import ProvisionHeaderActions from 'components/NewProvisionCustomer/components/headerActions/ProvisionHeaderActions/ProvisionHeaderActions';
import CreateUserProvisionPanel from 'components/NewProvisionCustomer/components/panels/CreateUserProvisionPanel/CreateUserProvisionPanel';
import CreateClustersProvisionPanel from 'components/NewProvisionCustomer/components/panels/CreateClustersProvisionPanel/CreateClustersProvisionPanel';
import CommunicateProvisionPanel from 'components/NewProvisionCustomer/components/panels/CommunicateProvisionPanel/CommunicateProvisionPanel';
import { showErrorMessage } from 'utils/errors';
import {
  useCreateAccount,
  useProvisionOptions,
} from 'hooks/provisionCustomerQueries';
import { NewAccount } from 'types/provisionCustomer.types';

const { Panel } = Collapse;

function NewProvisionCustomer() {
  let [form] = Form.useForm();
  let [activeKey, setActiveKey] = useState<any>(['1']);
  let [clustersActiveKey, setClustersActiveKey] = useState(['1']);
  const [clustersNameCount, setClustersNameCount] = useState(['Cluster 1']);
  const [clusters, setClusters] = useState<any>([]);

  let history = useHistory();

  const { data: provisionOptions, isLoading: provisionOptionsIsPending } =
    useProvisionOptions();

  const {
    mutateAsync: mutateCreateAccount,
    isLoading: isMutateCreateAccountLoading,
  } = useCreateAccount();

  useEffect(() => {
    setClusters([
      {
        userRole: provisionOptions?.userRoles[0],
        // analyticsSizeID: options.value,
        // region: provisionOptions?.clusterRegions[0].region,
        // cluster_code: provisionOptions?.clusterRegions[0].cluster_code,
        // environmentType: options.value,
        autoSuspend: 'Never',
      },
    ]);
  }, [provisionOptions]);

  useEffect(() => {
    form.setFieldsValue(
      provisionOptions
        ? {
            user: {
              permission: provisionOptions?.permissions[0],
              accountType:
                form?.getFieldsValue().user.accountType ||
                provisionOptions?.accountType[0].id,
              slaType: provisionOptions?.availableSlaTypes[0].id,
            },
            clusters: clusters,
            email: {
              bcc: provisionOptions?.controlPanelEmail,
            },
          }
        : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusters]);

  async function handleFinish(account: NewAccount) {
    try {
      await mutateCreateAccount({ account });
      history.push('/provision-user');
    } catch (error: any) {
      console.log(error);
    }
  }

  function handleFinishFailed(e) {
    let errors = e.errorFields;
    let openKeys: any = [];
    let clustersOpenKeys: any = [];
    errors.forEach((e: any) => {
      if (e.name[0] === 'user') {
        openKeys.push('1');
      } else if (e.name[0] === 'clusters') {
        clustersOpenKeys.push((e?.name[1] + 1).toString());
        openKeys.push('2');
      } else if (e.name[0] === 'email') {
        openKeys.push('3');
      }
    });
    setActiveKey(s => _.uniq([...s, ...openKeys]));
    setClustersActiveKey(s => {
      return _.uniq([...s, ...clustersOpenKeys]);
    });
  }

  let clusterSizes = provisionOptions?.clusterSizes
    ? Object.entries(provisionOptions?.clusterSizes).map(
        ([key, value]: any) => {
          return {
            key,
            display: value.description,
          };
        },
      )
    : undefined;

  function addNewCluster() {
    const nextClusterNameCount = `Cluster ${clustersNameCount.length + 1}`;
    setClustersNameCount(prev => [...prev, nextClusterNameCount]);
    setClustersActiveKey(prev => [
      ...prev,
      (clustersNameCount.length + 1).toString(),
    ]);
    setClusters(prev => [
      ...prev,
      {
        userRole: provisionOptions?.userRoles[0],
        // csize: options.value,
        region: provisionOptions?.clusterRegions[0].region,
        // environmentType: options.value,
        autoSuspend: 'Never',
      },
    ]);
  }

  function removeCluster(clusterNameCount, clusterKey) {
    if (clustersNameCount[clustersNameCount.length - 1] === clusterNameCount) {
      setClustersNameCount(clusters =>
        clusters.filter(cluster => cluster !== clusterNameCount),
      );
      setClustersActiveKey(prev => prev.filter(key => +key !== +clusterKey));
    } else {
      const currentIndex = clustersNameCount.findIndex(
        clName => clName === clusterNameCount,
      );
      setClustersNameCount(_ => [
        ...clustersNameCount.filter((_, i) => i < currentIndex),
        `Cluster ${clustersNameCount.length - 1}`,
      ]);
      clustersActiveKey
        .filter((_, i) => i !== currentIndex)
        .map(activeKey => {
          if (+activeKey <= currentIndex) {
            return activeKey;
          } else {
            return (+activeKey - 1).toString();
          }
        });
    }

    setClusters(prev => prev.filter((_, index) => +index !== +clusterKey - 1));

    mapClusterSize(clusters.filter((_, i) => +clusterKey - 1 !== i));
  }

  function mapClusterSize(currentClusters) {
    // check ...
    let accountType = 2;
    const trackMaxSize = new Set();

    for (let i = 0; i < currentClusters?.length; i++) {
      trackMaxSize.add(currentClusters[i].csize);
    }

    if (trackMaxSize.has('larges4') || trackMaxSize.has('larges3')) {
      accountType = 4;
    } else if (trackMaxSize.has('larges2')) {
      accountType = 3;
    }

    form.setFieldsValue({
      user: {
        accountType: accountType,
      },
    });
  }

  return provisionOptions ? (
    <div className="NewProvisionCustomer">
      <div className="NewProvisionCustomer__collapse_container">
        <Form
          form={form}
          name="NewProvisionCustomer"
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
        >
          <Collapse
            ghost
            bordered={false}
            className="Collapse"
            collapsible="header"
            expandIcon={() => null}
            activeKey={activeKey}
            onChange={keys => setActiveKey(keys)}
          >
            <Panel
              forceRender
              header={
                <CollapseHeader
                  activeKey={activeKey}
                  provisionKey="1"
                  text="1. Create User"
                >
                  <ProvisionHeaderActions
                    activeKey={activeKey}
                    provisionKey="1"
                  />
                </CollapseHeader>
              }
              key="1"
            >
              <CreateUserProvisionPanel
                form={form}
                provisionOptionsIsPending={provisionOptionsIsPending}
                provisionOptions={provisionOptions}
              />
            </Panel>

            <Panel
              forceRender
              header={
                <CollapseHeader
                  activeKey={activeKey}
                  provisionKey="2"
                  text="2. Create Clusters"
                >
                  <ProvisionHeaderActions
                    activeKey={activeKey}
                    provisionKey="2"
                  />
                </CollapseHeader>
              }
              key="2"
            >
              <CreateClustersProvisionPanel
                clustersNameCount={clustersNameCount}
                addNewCluster={addNewCluster}
                removeCluster={removeCluster}
                clusterSizes={clusterSizes}
                provisionOptionsIsPending={provisionOptionsIsPending}
                provisionOptions={provisionOptions}
                activeKey={clustersActiveKey}
                setActiveKey={setClustersActiveKey}
                form={form}
                clusters={clusters}
                mapClusterSize={mapClusterSize}
              />
            </Panel>

            <Panel
              forceRender
              header={
                <CollapseHeader
                  activeKey={activeKey}
                  provisionKey="3"
                  text="3. Communicate"
                >
                  <ProvisionHeaderActions
                    activeKey={activeKey}
                    provisionKey="3"
                  />
                </CollapseHeader>
              }
              key="3"
            >
              <CommunicateProvisionPanel
                form={form}
                provisionOptions={provisionOptions}
              />
            </Panel>
          </Collapse>

          <div className="NewProvisionCustomer__actions">
            <Button
              loading={isMutateCreateAccountLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  ) : null;
}

export default NewProvisionCustomer;
