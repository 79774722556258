import React, { useState, useMemo } from 'react';
import './CloudCapacityManagementTable.less';
import { Input, Popover, Spin, Table, Tag } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { green, blue, gold, yellow } from '@ant-design/colors';
import ClusterSizeCellModal from '../ClusterSizeCellModal/ClusterSizeCellModal';
import {
  useClusterSizesPerCloudProviders,
  useCreateNewClusterSize,
} from 'hooks/capacityManagementQueries';
import {
  ClusterSizesPerClusterCodeItem,
  OperatorPerPlatformItem,
} from 'types/capacityManagement.types';

function CloudCapacityManagementTable({
  operatorsPerPlatform,
  cloudProviderID,
  cloudProviderKey,
  showModalForNewSize,
  setShowModalForNewSize,
}: {
  operatorsPerPlatform: OperatorPerPlatformItem[];
  cloudProviderID: number;
  cloudProviderKey: string;
  showModalForNewSize: boolean;
  setShowModalForNewSize: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const openModalForNewSize = showModalForNewSize && !!cloudProviderID;

  const {
    data: clusterSizesPerCloudProviders,
    isLoading: isClusterSizesPerCloudProvidersLoading,
  } = useClusterSizesPerCloudProviders({
    cloudProviderID: cloudProviderID.toString(),
  });

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showCellEditModal, setShowCellEditModal] = useState(false);
  const [clickedCellValue, setClickedCellValue] = useState<
    ClusterSizesPerClusterCodeItem & { operatorAlias: string }
  >();

  const {
    mutateAsync: mutateCreateNewClusterSize,
    isLoading: isCreateNewClusterSizeLoading,
  } = useCreateNewClusterSize();
  async function handleFillSize({ currentClusterCode, record }) {
    const newClusterSize = {
      name: record.name,
      displayName: record.displayName,
      overCommitment: record.overCommitment,
      cloudProviderID: record.regionProvider.cloudProviderID,
      rolesIDs: record.roles.map(role => role.role.id),
      operatorClusterCodes: [
        {
          code: currentClusterCode,
          label: JSON.parse(record.nodeLabel),
          cpu: record.cpu,
          memoryRequest: record.memoryRequest,
          memoryLimit: record.memoryLimit,
          memoryText: record.memoryText,
        },
      ],
    } as any;
    await mutateCreateNewClusterSize({
      newClusterSize,
    });
  }

  // Filter dynamic columns based on search term
  const filteredOperators = useMemo(() => {
    if (!searchTerm.trim()) return operatorsPerPlatform;
    return operatorsPerPlatform.filter(operator =>
      operator.clusterCode.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [operatorsPerPlatform, searchTerm]);

  const columns = useMemo(() => {
    const baseColumns: ColumnsType<any> = [
      {
        title: (
          <article className="cloud-capacity-management-table__size-field-header">
            Size / Operator
            <Popover
              content={
                <div>
                  <ul>
                    <li>
                      Only <b>main sizes</b> from <b>xsmall</b> to{' '}
                      <b>2xlarge</b> will be available in Cloud Portal while
                      creating a cluster.
                    </li>
                    <li>
                      <b>Main sizes</b> from <b>xsmall</b> to <b>10xlarge</b>{' '}
                      cannot be deleted.
                    </li>
                    <li>
                      Sizes <b>other than the main sizes</b> can be deleted only
                      if they are{' '}
                      <b>
                        <i>not</i>
                      </b>{' '}
                      used in a cluster.
                    </li>
                  </ul>
                </div>
              }
            >
              <InfoCircleTwoTone
                twoToneColor={gold[5]}
                color={gold[7]}
                style={{ color: gold[5] }}
              />
            </Popover>
          </article>
        ),
        dataIndex: 'size',
        fixed: 'left',
        width: 200,
        render(value) {
          return (
            <section className="cloud-capacity-management-table__size-cell">
              <article className="cloud-capacity-management-table__size-cell-label">
                <b>{value}</b>
              </article>
            </section>
          );
        },
      },
    ];

    const dynamicColumns = (filteredOperators || []).map(operator => ({
      title: (
        <article>
          <label>
            <b>{operator.clusterCode}</b>
          </label>
        </article>
      ),
      align: 'center' as any,
      dataIndex: operator.clusterCode,
      width: 300,
      className: `cloud-capacity-management-table__memory-cell-wrapper table-cell ${operator.clusterCode}`,
      render(cellValue, { size }, index) {
        if (!cellValue && isCreateNewClusterSizeLoading) {
          return <Spin />;
        }
        if (!cellValue) {
          return (
            <>
              Create Size in <b>{operator.clusterCode}</b>
            </>
          );
        }
        const { memoryRequest, memoryLimit, cpu, overCommitment } = cellValue;
        return (
          <section className="cloud-capacity-management-table__memory-cell-card-wrapper">
            <section className="cloud-capacity-management-table__memory-cell-card">
              <section className="cloud-capacity-management-table__memory-cell-card-details">
                <span style={{ color: green[6] }}>
                  Request: {memoryRequest} GB
                </span>{' '}
                /{' '}
                <span style={{ color: blue[6] }}>Limit: {memoryLimit} GB</span>
              </section>
              <section className="cloud-capacity-management-table__memory-cell-card-details">
                <span style={{ color: yellow[6] }}>CPU: {cpu} vCPU</span>
              </section>
            </section>
            <Tag
              className="cloud-capacity-management-table__overcommitment-cell-tag"
              color={overCommitment.excluded ? 'red' : 'green'}
              icon={
                overCommitment.excluded ? (
                  <CloseCircleOutlined />
                ) : (
                  <CheckCircleOutlined />
                )
              }
            >
              Over Commitment
            </Tag>
          </section>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (e: any) => {
            const isFillingSizeInOperator = !record[operator.clusterCode];
            if (isFillingSizeInOperator) {
              // const s = record[clusterCode];
              const anyAlreadyFilledOperatorName = Object.keys(record).find(
                rec => rec !== 'size',
              );
              const itsRecord = record[anyAlreadyFilledOperatorName];

              handleFillSize({
                currentClusterCode: operator.clusterCode,
                record: itsRecord,
              });
            } else {
              const cellValue = {
                ...record[operator.clusterCode],
                operatorAlias: operator.clusterCode,
                clusterCode: operator.clusterCode,
              };
              setShowCellEditModal(true);
              setClickedCellValue(cellValue);
            }
          },
        };
      },
    }));

    return [...baseColumns, ...dynamicColumns];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOperators, isCreateNewClusterSizeLoading]);

  return (
    <>
      <Input.Search
        placeholder="Search operators..."
        onChange={e => setSearchTerm(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <Table
        rowKey="size"
        className="antd-table-customized-scroll responsive-table"
        bordered
        dataSource={clusterSizesPerCloudProviders}
        columns={columns}
        pagination={false}
        scroll={{ x: 1400, y: 1400 }}
        sticky
      />

      {showCellEditModal ? (
        <ClusterSizeCellModal
          clusterSizeData={clickedCellValue}
          cloudProviderKey={cloudProviderKey}
          close={() => setShowCellEditModal(false)}
        />
      ) : openModalForNewSize ? (
        <ClusterSizeCellModal
          cloudProviderID={cloudProviderID}
          cloudProviderKey={cloudProviderKey}
          close={() => setShowModalForNewSize(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default CloudCapacityManagementTable;
