import {
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-react';
import { CSize } from './cluster';

export interface SignUpUserData {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  password?: string;
  company?: string;
  jobTitle?: string;
  phone?: string;
  acceptTerms?: boolean;
  acceptPrivacy?: boolean;
  optInLocation?: string;
  rm_landing_page_url?: string;
  rm_referring_url?: string;
  rm_destination_url?: string;
  rm_person_id?: string;
  bypassValidationFlag?: boolean;
}

interface UserRole {
  createdAt: string;
  description: string;
  id: number;
  name: string;
  updatedAt: string;
}

export type meResponse = {
  user: {
    id: string;
    email: string;
    acceptTerms: boolean;
    company: string;
    cost_center: string;
    country: string;
    firstName: string;
    fullName: string;
    industryName: string;
    jobTitle: string;
    lastName: string;
    organization: string;
    phone: string;
    region: string;
    timezone: string;
    azureOffer: string;
    website: string;
    accountManager: boolean;
    confirmed: boolean;
    credit: number;
    enableMarketplace: boolean;
    isDummyPassword: boolean;
    isPartner: boolean;
    isProvision: boolean;
    newFlow: boolean;
    optInLocation: string;
    permission: string;
    reminder: number;
    role: Role;
    showConsentFlow: boolean;
    trialExpiration: string;
    userConsent: string;
    uuid: string;
    admin: boolean;
    assignedCluster: boolean;
    crypted: string;
    newUser: boolean;
    trialExpired: boolean;
    assignedParkedCluster: boolean;
    roles: string;
    lockedActions: string;
    createdAt: string;
    lastLoginAt: string;
    last_password_reset: string;
    user_id: string;
    blocked: boolean;
    loginProvider: string;
    toggleFeatures: ToggleFeatures[];
    instancesCount: number;
    industry: Industry;
    actions: {
      action: pageAndActionItem<userRolesActions, userRolesPages>;
    }[];
    pages: userPage[];
  };
};
export type ToggleFeatures = {
  id: number;
  name: string;
  key: string;
  published: boolean;
  toggle: boolean;
  createdAt: string;
  updatedAt: string;
};
export type Industry = {
  id: number;
  name: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
};

export interface User {
  id: string;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  phone: string;
  company: string;
  jobTitle: string;
  isPartner: boolean;
  internalUser: boolean;
  instancesCount: number;
  trialExpired: boolean;
  createdAt: string;
  role: UserRole;
  features: any[];
  toggleFeatures: any[];
  permission: string;
}

export interface UserConfigurations {
  allowedInstances: { count: number };
  pythonLibs: { value: string };
  showDiskSize: {
    enabled: boolean;
    value: number;
    min: number;
    max: number;
  };
  showAlwaysAlive: { enabled: boolean; value: boolean };
  showLatestRelease: { enabled: boolean; value: string };
  showInstanceSize: { enabled: boolean; value: CSize };
  showTenants: { enabled: boolean; value: number };
  showInstallation: { enabled: boolean; value: string };
}

export type SessionContextType = {
  user: UserData;
  isAuthenticated: boolean;
  isLoading: boolean;
  error?: Error;
  getAccessTokenSilently: {
    (
      options: GetTokenSilentlyOptions & {
        detailedResponse: true;
      },
    ): Promise<any>;
    (options?: GetTokenSilentlyOptions): Promise<any>;
    (options: GetTokenSilentlyOptions): Promise<any>;
  };
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  logout: (options?: LogoutOptions) => void;
  reloadUser: () => void;
};

export type userPage = {
  page: pageAndActionItem<userRolesPages, userRolesPages>;
  permission: string;
};
type pageAndActionItem<T1, T2> = {
  createdAt: string;
  id: number;
  name: string;
  type: T1;
  updatedAt: string;
  dependencies: T2[];
};
export type UserData = meResponse &
  User &
  UserExtensions & {
    uuid: string;
    email: string;
    role: string;
    fullName: string;
    trialExpired: boolean;
    redirectTo: string | '';
    showConsentFlow: boolean;
    confirmed: boolean;
    email_verified: boolean;
    pages: userPage[];
    actions: {
      action: pageAndActionItem<userRolesActions, userRolesPages>;
    }[];
    roles: string;
    userRolesPagesPermissions: Record<userRolesPages, string>;
    userRolesActions: Record<userRolesActions, true>;
    platformActions: PlatformActions[];
  };

export type getUserRolesResponse = {
  page: number;
  limit: number;
  total: number;
  userRoles: userRoleType[];
};
export type userRoleType = {
  id: number;
  name: string;
  canBeDeleted: boolean;
  createdAt: string;
  updatedAt: string;
};
export type userRolePageItem = {
  page: number;
  permission: string;
};
export type userRoleItem = {
  name: string;
  actions: number[];
  pages: userRolePageItem[];
};
export type userRoleCreateOrEditEndpoint = {
  name: string;
  actions: { id: number; dependencies: userRolesPages[] }[];
  pages: { page: number; permission: string; name: string }[];
};
export type userRoleModalForm = Omit<
  userRoleCreateOrEditEndpoint,
  'actions'
> & {
  actions: number[];
};
export type getUserRolesOperationsResponse = {
  actions: pageAndActionItem<userRolesActions, userRolesPages>[];
  pages: pageAndActionItem<userRolesPages, userRolesPages>[];
};

export interface Role {
  id: number;
  name: string;
  displayName: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export type UserAccountType = {
  id: number;
  name: string;
  displayName: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};
export type getUserAccountTypesResponse = {
  roles: UserAccountType[];
};

export type UserExtensions = {
  toggleFeatures: any[];
  instancesCount: number;
  userDataIsSet?: boolean;
  userRolesPagesPermissions: {};
  userRolesActions: {};
};

export type getUserResponseUserItem = {
  id: string;
  email: string;
  acceptTerms: boolean;
  invitedAccount: boolean;
  company: string;
  cost_center: string;
  country: string;
  firstName: string;
  fullName: string;
  industryName: string;
  jobTitle: string;
  lastName: string;
  organization: string;
  phone: string;
  region: string;
  timezone: string;
  accountManager: boolean;
  assignedCluster: boolean;
  confirmed: boolean;
  credit: number;
  enableMarketplace: boolean;
  isDummyPassword: boolean;
  isPartner: boolean;
  isProvision: boolean;
  newFlow: boolean;
  newUser: boolean;
  optInLocation: string;
  permission: string;
  reminder: number;
  role: {
    name: string;
    displayName: string;
  };
  roles: string;
  showConsentFlow: boolean;
  trialExpiration: Date;
  userConsent: boolean | string;
  uuid: string;
  admin: boolean;
  trialExpired: boolean;
  createdAt: Date;
  lastLoginAt: Date;
  last_password_reset: string;
  user_id: string;
  loginProvider: string;
  toggleFeatures: any[];
  azureOffer?: string;
  website?: string;
  crypted?: string;
  blocked?: boolean;
};
export type getUserResponse = {
  page: number;
  limit: number;
  total: number;
  users: getUserResponseUserItem[];
};

export interface PlatformActions {
  name: string;
  platforms: string;
  createdAt: string;
  displayName: string;
  id: string;
  updatedAt: string;
}

export interface SignUpResult {
  user: User;
}

export interface ValidEmailResult {
  valid: boolean;
}

export interface SignInUserData {
  email: string;
  password: string;
}

export interface SignInResult {
  user: User;
  configurations: UserConfigurations;
  redirectTo: string;
  location: string;
}

export interface ActivationResult {
  user: User;
  token: string;
  redirectTo: string;
}

export interface SupportData {
  email?: string;
  message: string;
}

export interface SupportResult {
  message: string;
}

interface Industrie {
  id: number;
  name: String;
  enabled: boolean;
  createdAt: String;
  updatedAt: String;
}

export interface GetIndustriesResult {
  industries: Industrie[];
}

export interface PersonalInformationEdit {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  company?: string;
  phone?: string;
}

export interface PersonalInformationEditResult {
  message: string;
}

export interface PasswordData {
  currentPassword: string;
  newPassword: string;
}

export interface PaymentSetupResult {
  secret: string;
  customer: string;
}

export interface UserPaymentMethodResponse {
  data: {
    brand: string;
    createdAt: string;
    customerID: string;
    deletedAt?: string | null;
    expiration: string;
    id: string;
    last4: string;
    pmID: string;
    updatedAt: string;
    userID: string;
  } | null;
}

export interface CanDowngradeResponse {
  can: boolean;
}

export interface Stat {
  date: string;
  totalCost: number;
  units: number;
  unitsCost: number;
  usage: number;
  usageCost: number;
  users: number;
  usersCost: number;
}

export interface GetStates {
  stats: Stat[];
}

interface InstanceStat {
  date: string;
  dateFormatted: string;
  id: number;
  totalCost: number;
  unitCostPerDay: number;
  units: number;
  unitsCost: number;
  usage: number;
  usageCost: number;
  userCostPerDay: number;
  users: number;
  usersCost: number;
}

interface StatInstance {
  id: string;
  name: string;
  stats: InstanceStat[];
}

export interface GetSatesForADate {
  instances: StatInstance[];
}

export enum userRolesPages {
  DATA_AGENT = 'Data Agent',
  CLOUD_INSIGHTS = 'Cloud Insights', //   /clustersstatus
  TOGGLE_FEATURES = 'Toggle Features',
  ACCOUNT_MANAGER = 'Account Manager',
  PLATFORM_ACTIONS = 'Platform Actions',
  NOTIFICATIONS = 'Notifications',
  BACKUPS = 'Backups',
  CLUSTERS = 'Clusters',
  CLOUD_ACTION_CENTER = 'Cloud Action Center',
  AUDIT_HISTORY = 'Audit History', //  +  audit tab in user
  IMAGES = 'Images',
  ROLES = 'Roles',
  OPERATORS = 'Operators', //   /contextmanager
  PROVISION_USERS = 'Provision Users',
  INFRASTRUCTURE = 'Infrastructure',
  USERS = 'Users',
  COST_AWARENESS = 'Cost Awareness',
  BLUEPRINTS_EXPORTS = 'Blueprints Exports',
  FEATURE_BITS = 'Feature Bits',
  CONFIGURATIONS = 'Configurations',
  CLI_COMMAND = 'Cli Command',
  OVER_COMMITMENT = 'Over Commitment',
  CHIDORI = 'Chidori',
  INCORTA_ONE = 'Incorta One',
  CLUSTER_SIZES = 'Cluster Sizes',
  RESTORED_CLUSTERS = 'Restored Clusters',
  SIZE_MANAGEMENT = 'Size Management',
}
export enum userRolesPagesPermissionsValues {
  CAN_READ = '1',
  CAN_EDIT = '2',
  CAN_DELETE = '3',
}

export enum userRolesActions {
  ARCHIVE_CLUSTER = 'Archive Cluster', // ***** clusters page action
  ASSIGN_UNASSIGN_USER_TOGGLE_FEATURE = 'Assign/Unassign User Toggle Feature', // ***** togglefeatures page -> user icon -> action
  GET_USER = 'Get User', // ***** User Details user info btn
  DELETE_USER_PACKAGES = 'Delete User Packages', // ***** users page -> packages tab (Delete btn)
  BLOCK_USER = 'Block User', // ***** users -> block btn
  LOCK_CLUSTER = 'Lock Cluster', // ***** clusters page action
  RESET_USER_PASSWORD = 'Reset User Password', // ***** users -> reset pass btn
  BLOCK_CLUSTER_FROM_URL = 'Block Cluster From URL', // ***** clusters page action
  TRANSFER_CLUSTER = 'Transfer Cluster', // ***** clusters page action
  CREATE_UPDATE_USER_PACKAGES = 'Create/Update User Packages', // ***** users page -> packages tab (Add btn)
  CONFIGURE_AZURE_PLAN = 'Configure Azure Plan', // ***** sys mgmt config azureRegions
  CLUSTER_ROLES = 'Cluster Roles', // ***** list instance users -> invite user btn
  USER_CONSUMPTION = 'User Consumption', // ***** users page -> Consumption tab
  VIEW_USER_PACKAGES = 'View User Packages', // ***** User Details -> packages tab
  UPDATE_CHIDORI_SIZES_TABLE = 'Update Chidori Sizes Table',
  LOGS_ANALYSIS = 'Logs Analysis',
  RELEASE_MANAGER_CONFIGURATIONS = 'Release Manager Configurations',
  EDIT_USER_ROLES = 'Edit User Roles',
  REGISTER_PARTNER_ACTION = 'Register Incorta One Partner',
  ADD_PREMIUM_PACKAGE_ACTION = 'Add Premium package to cluster',
  REMOVE_PREMIUM_PACKAGE_ACTION = 'Remove Premium package from cluster',
  BUSINEE_NOTEBOOK_ACTION = 'Manage Business User Notebook',
  CLUSTER_MANAGEMENT_ACTION = 'Manage Cluster Details',
  CONNECTION_MNGMENT_CLUSTER = 'Manage Cluster Connection',
  CLUSTER_COST_MANAGEMENT_ACTION = 'Mange Cluster Tags',
  CLUSTER_UPGRADE_ACTION = 'Manage Cluster Upgrades',
  CLUSTER_CLONE_ACTION = 'Manage Cluster Clone',
  CLUSTER_SECRET_ACTION = 'Manage Cluster Secrets',
}
